import * as React from 'react';
import { Redirect, Route, RouteProps } from 'react-router-dom';

interface Props extends RouteProps {
  isAuthenticated?: boolean;
  redirectTo: string;
}

function PublicRoute({ isAuthenticated, component: Component, redirectTo, render, ...rest }: Props) {
  return (
    <Route
      {...rest}
      render={props => {
        if (isAuthenticated) {
          return <Redirect to={redirectTo} />;
        }

        if (Component) {
          return <Component {...props} />;
        }

        if (render) {
          return render(props);
        }

        return null;
      }}
    />
  );
}

export default PublicRoute;
