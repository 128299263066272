import { Button, Col, Form, Icon, Input, List, Row, Select, Tooltip } from 'antd';
import * as R from 'ramda';
import * as React from 'react';
import { ICouponRule } from '../../../generated/graphql';
import * as formatter from '../../../lib/formatter';
import * as parser from '../../../lib/parser';
import CouponRuleConditions from './CouponRuleConditions';
import { useForm } from './Form';

function CouponRules() {
  const formik = useForm();

  const add = () => {
    formik.setFieldValue('rules', [...formik.values.rules, { conditions: [] }]);
  };

  const remove = (index: number) => {
    formik.setFieldValue('rules', R.remove(index, 1, formik.values.rules));
  };

  return (
    <>
      <List
        header="Rules"
        dataSource={formik.values.rules}
        renderItem={(rule: ICouponRule, i: number) => (
          <List.Item>
            <Row type="flex" align="middle" gutter={16}>
              <Col>
                <Form.Item>
                  <Icon type="bars" theme="outlined" />
                </Form.Item>
              </Col>

              <Col>
                <Form.Item>
                  <Select
                    onChange={(value: string) => formik.setFieldValue(`rules[${i}].type`, value)}
                    value={formik.values.rules[i].type}
                    style={{ minWidth: '200px' }}
                  >
                    <Select.Option value="amount">Amount</Select.Option>
                    <Select.Option value="percentage">Percentage</Select.Option>
                  </Select>
                </Form.Item>
              </Col>

              <Col>
                <Form.Item>
                  <Input
                    name={`rules[${i}].value`}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                      const { name, value } = event.target;

                      switch (formik.values.rules[i].type) {
                        case 'amount':
                          formik.setFieldValue(name, parser.parseCurrency(value));
                          break;
                        case 'percentage':
                          formik.setFieldValue(name, parser.parsePercentage(value));
                          break;
                        default:
                          formik.setFieldValue(name, value);
                          break;
                      }
                    }}
                    value={(() => {
                      const { value, type } = formik.values.rules[i];

                      switch (type) {
                        case 'amount':
                          return formatter.formatDollars(value);
                        case 'percentage':
                          return formatter.formatPercentage(value);
                        default:
                          return value;
                      }
                    })()}
                    style={{ minWidth: '100px' }}
                    min={0}
                    required
                  />
                </Form.Item>
              </Col>

              <Col>
                <Form.Item>
                  <Tooltip title="Remove rule">
                    <Button type="dashed" htmlType="button" onClick={() => remove(i)}>
                      <Icon type="minus" />
                    </Button>
                  </Tooltip>
                </Form.Item>
              </Col>

              <Col offset={4}>
                <CouponRuleConditions index={i} />
              </Col>
            </Row>
          </List.Item>
        )}
      />

      <Button type="dashed" htmlType="button" onClick={add}>
        <Icon type="plus" /> Add rule
      </Button>
    </>
  );
}

export default CouponRules;
