import { Cascader } from 'antd';
import { CascaderOptionType } from 'antd/lib/cascader';
import { ApolloError } from 'apollo-client';
import { GraphQLError } from 'graphql';
import gql from 'graphql-tag';
import { DateTime } from 'luxon';
import * as R from 'ramda';
import { CSSProperties, default as React, useCallback, useState } from 'react';
import apolloClient from '../../../lib/apolloClient';

interface ITrip {
  tripId: string;
  startDate: string;
  endDate: string;
  maxSeats: string;
  seatsTaken: string;
}

const TRIPS_QUERY = gql`
  query trips($filter: TripsFilter) {
    trips(filter: $filter) {
      tripId
      startDate
      endDate
      maxSeats
      seatsTaken
    }
  }
`;

interface Props {
  style?: CSSProperties;
  options: Array<CascaderOptionType>;

  onChange?(value: Array<string>, selectedOptions?: Array<CascaderOptionType>): void;
}

function SelectCascader(props: Props) {
  const { style, options, onChange } = props;
  const [opts, setOpts] = useState(options);

  const loadData = useCallback(
    async (selectedOptions?: Array<CascaderOptionType>) => {
      if (!selectedOptions) {
        return;
      }

      const targetOption = selectedOptions[selectedOptions.length - 1];
      targetOption.loading = true;

      try {
        const { data } = await apolloClient.query({
          query: TRIPS_QUERY,
          variables: {
            filter: {
              locationId: targetOption.value,
              archived: false,
              disabled: false
            }
          }
        });

        targetOption.children = R.map((trip: ITrip) => ({
          label: `${DateTime.fromSQL(trip.startDate).toLocaleString(DateTime.DATE_MED)} - ${DateTime.fromSQL(
            trip.endDate
          ).toLocaleString(DateTime.DATE_MED)} (${trip.seatsTaken}/${trip.maxSeats})`,
          value: trip.tripId,
          disabled: trip.seatsTaken >= trip.maxSeats
        }))(data.trips);
      } catch (err) {
        console.error(err);
        (err as ApolloError).graphQLErrors.forEach((error: GraphQLError) => console.log(error.message));
      } finally {
        targetOption.loading = false;
      }

      setOpts([...opts]);
    },
    [opts]
  );

  return <Cascader style={style} options={opts} loadData={loadData} onChange={onChange} changeOnSelect />;
}

export default SelectCascader;
