import { Button, Form, Icon, Input } from 'antd';
import { Field, FieldProps, Form as FormikForm } from 'formik';
import * as React from 'react';

interface Props {
  formik: any;
}

function LoginForm(props: Props) {
  const { formik } = props;
  return (
    <FormikForm>
      <Form.Item>
        <Field
          name="email"
          render={({ field }: FieldProps<any>) => (
            <Input
              {...field}
              prefix={<Icon type="user" style={{ color: 'rgba(0,0,0,.25)' }} />}
              type="email"
              size="large"
              placeholder="Email Address"
              required
            />
          )}
        />
      </Form.Item>
      <Form.Item>
        <Field
          name="password"
          render={({ field }: FieldProps<any>) => (
            <Input
              {...field}
              prefix={<Icon type="lock" style={{ color: 'rgba(0,0,0,.25)' }} />}
              type="password"
              size="large"
              placeholder="Password"
              required
            />
          )}
        />
      </Form.Item>
      <Form.Item>
        <Button block size="large" htmlType="submit" loading={formik.isSubmitting}>
          Login
        </Button>
      </Form.Item>
    </FormikForm>
  );
}

export default LoginForm;
