import * as R from 'ramda';

export const parsePercentage = R.pipe<any, any, any, number, number, number>(
  R.replace(/%/g, ''),
  R.replace(/,/g, ''),
  (val: string) => parseInt(val, 10),
  R.defaultTo(0),
  R.multiply(0.01)
);

export const parseCurrency = R.pipe<any, any, any, number, number, number>(
  R.replace(/\$/g, ''),
  R.replace(/,/g, ''),
  (val: string) => parseInt(val, 10),
  R.defaultTo(0),
  R.multiply(100)
);
