import { Button, message } from 'antd';
import { ContentState, convertToRaw, EditorState } from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import { Field, FieldProps, Form, Formik } from 'formik';
import htmlToDraft from 'html-to-draftjs';
import * as React from 'react';
import { Editor } from 'react-draft-wysiwyg';
import styled from 'styled-components';
import { useGetPolicyQuery, useUpdatePolicyMutation } from '../../generated/graphql';
import config from '../../lib/config';

function Settings() {
  const query = useGetPolicyQuery();
  const [updatePolicy] = useUpdatePolicyMutation();

  if (query.loading) {
    return <div>loading...</div>;
  }

  const policy = query.data?.policy ?? '';
  const contentBlock = htmlToDraft(policy);
  const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks, contentBlock.entityMap);
  const editorState = EditorState.createWithContent(contentState);

  const onSubmit = async (input: any, formikActions: any) => {
    try {
      formikActions.setSubmitting(true);
      formikActions.setStatus(undefined);
      const raw = convertToRaw(input.editorState.getCurrentContent());
      const html = draftToHtml(raw);
      await updatePolicy({ variables: { input: { policy: html } } });
      message.success('Saved successfully!');
    } catch (err) {
      console.error(err);
      formikActions.setStatus(err);
      message.error('Save failed!');
    } finally {
      formikActions.setSubmitting(false);
    }
  };

  return (
    <Layout>
      <Button href={config.globalSalesDump} type="link">
        Sales Dump Spreadsheet
      </Button>
      <Formik
        initialValues={{
          editorState
        }}
        onSubmit={onSubmit}
        render={(formikProps: any) => (
          <Form>
            <Field
              name="editorState"
              render={({ field, form }: FieldProps<any>) => (
                <Editor
                  editorStyle={{
                    padding: '1rem'
                  }}
                  wrapperStyle={{
                    border: '1px solid #d9d9d9',
                    borderRadius: '4px',
                    margin: '1rem 0'
                  }}
                  editorState={field.value}
                  onEditorStateChange={(state: EditorState) => form.setFieldValue(field.name, state)}
                />
              )}
            />
            <Button type="primary" htmlType="submit" loading={formikProps.isSubmitting}>
              Submit
            </Button>
          </Form>
        )}
      />
    </Layout>
  );
}

const Layout = styled('section')`
  width: 100%;
  max-width: 1024px;
  margin: 0 auto;
`;

export default Settings;
