import { Alert, Button, Card, Checkbox, Descriptions, Input, message, Select, Timeline, Typography } from 'antd';
import { Field, FieldProps, Form, Formik } from 'formik';
import { GraphQLError } from 'graphql';
import { DateTime } from 'luxon';
import * as L from 'partial.lenses';
import * as R from 'ramda';
import * as RA from 'ramda-adjunct';
import * as React from 'react';
import { useState } from 'react';
import { Link, RouteComponentProps } from 'react-router-dom';
import styled from 'styled-components';
import AsyncComponent from '../../components/AsyncComponent/AsyncComponent';
import ChangelogTimeline from '../../components/ChangelogTimeline/ChangelogTimeline';
import Map from '../../components/Map/Map';
import {
  IBooking,
  IOrderPriceAdjustment,
  IPassport,
  ITraveler,
  useGetBookingQuery,
  useUpdateBookingMutation,
  useUpdateTravelerMutation
} from '../../generated/graphql';
import countries from '../../lib/countries';
import { formatCurrency } from '../../lib/currencyFormatter';
import links from '../../lib/links';
import ChangeTripModal from './components/ChangeTripModal';
import OrderPriceAdjustmentModal from './components/OrderPriceAdjustmentModal';
import TravelerForm from './components/TravelerForm';

interface Props extends RouteComponentProps<{ bookingId: string }> {}

function Edit(props: Props) {
  const { bookingId } = props.match.params;
  const query = useGetBookingQuery({ variables: { bookingId }, notifyOnNetworkStatusChange: true, errorPolicy: 'all' });
  const [updateBooking] = useUpdateBookingMutation();
  const [updateTraveler] = useUpdateTravelerMutation();
  const [visible, setVisible] = useState(false);
  const [orderPriceAdjustmentModalVisible, setOrderPriceAdjustmentModalVisible] = useState(false);

  const order = query.data?.booking?.order;

  const lastOrderPriceAdjustment = L.get(L.last, order?.priceAdjustments);
  const orderAmount = order?.amount ?? 0;

  const formatted = {
    orderAmount: formatCurrency(orderAmount),
    adjustedPrice: lastOrderPriceAdjustment ? formatCurrency(lastOrderPriceAdjustment.amount) : undefined
  };

  const errors = query.error?.graphQLErrors.map((error: GraphQLError, i: number) => (
    <Alert key={i} message={error.message} type="error" />
  ));

  const onSubmit = async (input: any, formikActions: any) => {
    try {
      formikActions.setSubmitting(true);
      formikActions.setStatus(undefined);

      try {
        await updateBooking({
          variables: {
            input: {
              bookingId: input.bookingId,
              tripId: input.tripId,
              returnCustomer: input.returnCustomer,
              roomArrangement: input.roomArrangement,
              status: input.status,
              friendName: input.friendName
            }
          }
        });
        message.success('Booking saved successfully.');
      } catch (err) {
        console.error(err);
        message.error(`Booking save failed. ${err.message}.`);
      }

      await Promise.all(
        RA.mapIndexed(async (traveler: ITraveler, index: number) => {
          try {
            await updateTraveler({
              variables: {
                input: R.pipe<ITraveler, ITraveler, ITraveler, ITraveler, ITraveler>(
                  L.remove(['__typename']),
                  L.remove(['address', '__typename']),
                  L.remove(['passport', '__typename']),
                  L.modify('passport', (passport: IPassport) => {
                    return RA.isNilOrEmpty(passport.number) &&
                      RA.isNilOrEmpty(passport.expiration) &&
                      RA.isNilOrEmpty(passport.country)
                      ? undefined
                      : passport;
                  })
                )(traveler) as any
              }
            });
            message.success(`Traveler ${index + 1} saved successfully.`);
          } catch (err) {
            console.error(err);
            message.error(`Traveler ${index + 1} save failed. ${err.message}.`);
          }
        })(input.travelers)
      );
    } catch (err) {
      console.error(err);
      formikActions.setStatus(err);
      message.error('Booking save failed!');
    } finally {
      formikActions.setSubmitting(false);
    }
  };

  return (
    <AsyncComponent
      {...query}
      allErrors
      render={({ booking }: { booking: IBooking }) => (
        <section
          style={{
            width: '100%',
            maxWidth: '1024px',
            margin: '0 auto'
          }}
        >
          {errors}

          {!!errors?.length && <br />}

          <Formik
            initialValues={booking}
            onSubmit={onSubmit}
            render={formikProps => (
              <Form>
                <Layout>
                  <Card
                    size="small"
                    title={
                      <Typography.Text
                        editable={{
                          editing: false,
                          onStart: () => setVisible(true)
                        }}
                      >
                        Trip Details
                      </Typography.Text>
                    }
                  >
                    <Descriptions column={1}>
                      <Descriptions.Item label="Country">
                        {
                          R.pipe(
                            R.find(R.propEq('code', booking.trip!.location!.country!)),
                            R.prop('name')
                          )(countries) as string
                        }
                      </Descriptions.Item>

                      <Descriptions.Item label="Location">
                        <Link to={links.editLocation(booking.trip!.location!.locationId)}>
                          {booking.trip!.location!.title!}
                        </Link>
                      </Descriptions.Item>

                      <Descriptions.Item label="Trip ID">
                        <Link to={links.editTrip(booking.trip!.tripId)}>{booking.trip!.tripId!}</Link>
                      </Descriptions.Item>

                      <Descriptions.Item label="Dates">
                        {DateTime.fromISO(booking.trip!.startDate!).toLocaleString(DateTime.DATE_MED)} -{' '}
                        {DateTime.fromISO(booking.trip!.endDate!).toLocaleString(DateTime.DATE_MED)}
                      </Descriptions.Item>
                    </Descriptions>
                  </Card>

                  {!!booking.order && (
                    <Card size="small" title="Order Details">
                      <Descriptions column={1}>
                        <Descriptions.Item label="Order ID">{booking.order.orderId}</Descriptions.Item>

                        <Descriptions.Item label="Amount">
                          <Typography.Text
                            editable={{
                              editing: false,
                              onStart: () => setOrderPriceAdjustmentModalVisible(true)
                            }}
                          >
                            <Typography.Text delete={!!lastOrderPriceAdjustment}>
                              {formatted.orderAmount}
                            </Typography.Text>
                            {lastOrderPriceAdjustment && (
                              <Typography.Text>&nbsp;{formatted.adjustedPrice}</Typography.Text>
                            )}
                          </Typography.Text>
                        </Descriptions.Item>

                        <Descriptions.Item label="Coupons">{booking.order?.couponIds?.join(', ')}</Descriptions.Item>
                      </Descriptions>

                      <Timeline>
                        <Map
                          data={booking.order?.priceAdjustments ?? []}
                          render={(priceAdjustment: IOrderPriceAdjustment) => (
                            <Timeline.Item key={priceAdjustment.id}>
                              <p>
                                {DateTime.fromISO(priceAdjustment.createTime!).toLocaleString(DateTime.DATETIME_SHORT)}
                              </p>
                              <p>
                                {formatCurrency(priceAdjustment.amount!)} {priceAdjustment.note}
                              </p>
                            </Timeline.Item>
                          )}
                        />
                      </Timeline>
                    </Card>
                  )}

                  <Card size="small" title="Booking Details">
                    <Descriptions column={1}>
                      <Descriptions.Item label="Booking ID">{booking.bookingId}</Descriptions.Item>

                      <Descriptions.Item label="Status">
                        <Field
                          name="status"
                          render={({ field, form }: FieldProps<any>) => (
                            <Select
                              style={{
                                minWidth: '100px'
                              }}
                              onChange={(value: string) => form.setFieldValue(field.name, value)}
                              value={field.value}
                            >
                              {R.map((status: string) => (
                                <Select.Option key={status} value={status}>
                                  {status}
                                </Select.Option>
                              ))(['APPROVED', 'PENDING', 'CANCELLED_REFUNDED', 'CANCELLED_HELD'])}
                            </Select>
                          )}
                        />
                      </Descriptions.Item>

                      <Descriptions.Item label="Return Customer">
                        <Field
                          name="returnCustomer"
                          render={({ field, form }: FieldProps<any>) => (
                            <Checkbox
                              onChange={() => form.setFieldValue(field.name, !field.value)}
                              defaultChecked={field.value}
                            />
                          )}
                        />
                      </Descriptions.Item>

                      <Descriptions.Item label="Room Arrangement">
                        <Field
                          name="roomArrangement"
                          render={({ field, form }: FieldProps<any>) => (
                            <Select
                              onChange={(value: string) => form.setFieldValue(field.name, value)}
                              value={field.value}
                            >
                              {R.map((status: string) => (
                                <Select.Option key={status} value={status}>
                                  {status}
                                </Select.Option>
                              ))(['solo', 'friend', 'couple'])}
                            </Select>
                          )}
                        />
                      </Descriptions.Item>

                      <Descriptions.Item label="Friend Name">
                        <Field name="friendName" render={({ field }: FieldProps<any>) => <Input {...field} />} />
                      </Descriptions.Item>

                      <Descriptions.Item label="Create Time">
                        {DateTime.fromISO(booking.createTime!).toLocaleString(DateTime.DATETIME_MED)}
                      </Descriptions.Item>
                    </Descriptions>

                    <div
                      style={{
                        display: 'grid',
                        gridGap: '1rem',
                        gridTemplateColumns: 'repeat(2, 1fr)',
                        gridTemplateRows: 'auto'
                      }}
                    >
                      {RA.mapIndexed((traveler: ITraveler, index: number) => (
                        <Card type="inner" title={`Traveler ${index + 1}`} key={traveler.travelerId!}>
                          <TravelerForm index={index} />
                        </Card>
                      ))(booking.travelers!)}
                    </div>
                  </Card>

                  <div>
                    <Button type="primary" htmlType="submit" icon="save" loading={formikProps.isSubmitting}>
                      Save
                    </Button>
                  </div>

                  {!!formikProps.status && (
                    <Alert message={formikProps.status.message} type="error" showIcon closable />
                  )}
                </Layout>
              </Form>
            )}
          />

          <br />

          <Card size="small" title="Changelogs">
            <ChangelogTimeline data={booking.changelogs ?? []} />
          </Card>

          <ChangeTripModal
            booking={booking}
            visible={visible}
            onCancel={result => {
              setVisible(false);
              if (result) {
                query.refetch();
              }
            }}
          />

          <OrderPriceAdjustmentModal
            order={booking.order}
            visible={orderPriceAdjustmentModalVisible}
            onCancel={() => setOrderPriceAdjustmentModalVisible(false)}
          />
        </section>
      )}
    />
  );
}

const Layout = styled('section')`
  display: grid;
  grid-gap: 1rem;
  grid-template-columns: repeat(1, 1fr);
  grid-template-rows: auto;
`;

export default Edit;
