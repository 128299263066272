import { Button, Col, Form, Icon, Input, List, Row, Select, Tooltip } from 'antd';
import * as R from 'ramda';
import * as React from 'react';
import { ICouponRuleCondition } from '../../../generated/graphql';
import * as formatter from '../../../lib/formatter';
import * as parser from '../../../lib/parser';
import { useForm } from './Form';

interface Props {
  index: number;
}

function CouponRuleConditions(props: Props) {
  const { index } = props;
  const formik = useForm();

  const add = () => {
    formik.setFieldValue(`rules[${index}].conditions`, [...formik.values.rules[index].conditions, {}]);
  };

  const remove = (i: number) => {
    formik.setFieldValue(`rules[${index}].conditions`, R.remove(index, 1, formik.values.rules[index].conditions));
  };

  return (
    <>
      <List
        header="Conditions"
        dataSource={formik.values.rules[index].conditions}
        renderItem={(condition: ICouponRuleCondition, i: number) => (
          <List.Item>
            <Row type="flex" align="middle" gutter={16}>
              <Col>
                <Form.Item>
                  <Icon type="bars" theme="outlined" />
                </Form.Item>
              </Col>

              <Col>
                <Form.Item>
                  <Select
                    onChange={(value: string) => formik.setFieldValue(`rules[${index}].conditions[${i}].field`, value)}
                    value={condition.field!}
                    style={{ minWidth: '100px' }}
                  >
                    <Select.Option value="price">Price</Select.Option>
                  </Select>
                </Form.Item>
              </Col>

              <Col>
                <Form.Item>
                  <Select
                    onChange={(value: string) =>
                      formik.setFieldValue(`rules[${props.index}].conditions[${i}].operator`, value)
                    }
                    value={condition.operator!}
                    style={{ minWidth: '200px' }}
                  >
                    <Select.Option value="=">equals</Select.Option>
                    <Select.Option value="<">less than</Select.Option>
                    <Select.Option value=">">greater than</Select.Option>
                    <Select.Option value="<=">less than or equals</Select.Option>
                    <Select.Option value=">=">greater than or equals</Select.Option>
                  </Select>
                </Form.Item>
              </Col>

              <Col>
                <Form.Item>
                  <Input
                    name={`rules[${index}].conditions[${i}].value`}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                      const { name, value } = event.target;
                      formik.setFieldValue(name, parser.parseCurrency(value));
                    }}
                    value={formatter.formatDollars(formik.values.rules[index].conditions[i].value)}
                    required
                  />
                </Form.Item>
              </Col>

              <Col>
                <Form.Item>
                  <Tooltip title="Remove condition">
                    <Button type="dashed" htmlType="button" onClick={() => remove(i)}>
                      <Icon type="minus" />
                    </Button>
                  </Tooltip>
                </Form.Item>
              </Col>
            </Row>
          </List.Item>
        )}
      />

      <Button type="dashed" htmlType="button" onClick={add}>
        <Icon type="plus" /> Add condition
      </Button>
    </>
  );
}

export default CouponRuleConditions;
