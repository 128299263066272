import { Icon, Spin } from 'antd';
import * as React from 'react';
import styled from 'styled-components';

const Loading = styled.div`
  height: 30vh;
  padding-top: 3rem;
  text-align: center;
`;

const LoadingSpinner = () => {
  return (
    <Loading>
      <Spin indicator={<Icon type="loading" style={{ fontSize: '5rem' }} spin />} />
    </Loading>
  );
};

export default LoadingSpinner;
