export default {
  apiURL: process.env.REACT_APP_API_URL || '',
  apiKey: process.env.REACT_APP_API_KEY || '',
  authDomain: process.env.REACT_APP_AUTH_DOMAIN || '',
  databaseURL: process.env.REACT_APP_DATABASE_URL || '',
  projectId: process.env.REACT_APP_PROJECT_ID || '',
  storageBucket: process.env.REACT_APP_STORAGE_BUCKET || '',
  checkoutURL: process.env.REACT_APP_CHECKOUT_URL || '',
  globalSalesDump: process.env.REACT_APP_GLOBAL_SALES_DUMP || ''
};
