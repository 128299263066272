import { Alert } from 'antd';
import { message } from 'antd/es';
import { Formik } from 'formik';
import * as L from 'partial.lenses';
import * as R from 'ramda';
import * as React from 'react';
import { RouteComponentProps } from 'react-router-dom';
import AsyncComponent from '../../components/AsyncComponent/AsyncComponent';
import Layout from '../../components/Layout';
import { useGetTripQuery, useUpdateTripMutation, useUpdateTripSkuMutation } from '../../generated/graphql';
import TripForm from './components/TripForm';

interface Props extends RouteComponentProps<{ tripId: string }> {}

function Edit(props: Props) {
  const { tripId } = props.match.params;
  const query = useGetTripQuery({ variables: { tripId } });
  const [updateTrip] = useUpdateTripMutation();
  const [updateTripSKU] = useUpdateTripSkuMutation();

  return (
    <AsyncComponent
      {...query}
      render={({ trip }) => {
        const initialValues = prepareInitialValues(trip);
        return (
          <Formik
            initialValues={initialValues}
            onSubmit={async (input: any, formikActions: any) => {
              try {
                formikActions.setSubmitting(true);
                formikActions.setStatus(undefined);

                try {
                  await updateTrip({
                    variables: {
                      input: R.omit(['locationId', 'earlyPrice', 'depositPrice', 'fullPrice'])(input) as any
                    }
                  });
                  message.success('Trip saved successfully');
                } catch (err) {
                  console.error(err);
                  message.error(`Early price save failed. ${err.message}.`);
                }

                const earlySKU = L.get(L.find(R.propEq('type', 'early')))(trip.skus);

                if (earlySKU) {
                  try {
                    const variables = {
                      input: {
                        tripId: input.tripId,
                        sku: earlySKU.sku,
                        price: input.earlyPrice
                      }
                    };
                    await updateTripSKU({ variables });
                    message.success('Early price saved successfully.');
                  } catch (err) {
                    console.error(err);
                    message.error(`Early price save failed. ${err.message}.`);
                  }
                } else {
                  message.error('Early price save failed. SKU not found.');
                }

                const depositSKU = L.get(L.find(R.propEq('type', 'deposit')))(trip.skus);

                if (depositSKU) {
                  try {
                    await updateTripSKU({
                      variables: {
                        input: {
                          tripId: input.tripId,
                          sku: depositSKU.sku,
                          price: input.depositPrice
                        }
                      }
                    });
                    message.success('Deposit price saved successfully.');
                  } catch (err) {
                    message.error(`Deposit price save failed. ${err.message}.`);
                  }
                } else {
                  message.error('Deposit price save failed. SKU not found.');
                }

                const fullSKU = L.get(L.find(R.propEq('type', 'full')))(trip.skus);

                if (fullSKU) {
                  try {
                    await updateTripSKU({
                      variables: {
                        input: {
                          tripId: input.tripId,
                          sku: fullSKU.sku,
                          price: input.fullPrice
                        }
                      }
                    });
                    message.success('Full price saved successfully.');
                  } catch (err) {
                    message.error(`Full price save failed. ${err.message}.`);
                  }
                } else {
                  message.error('Full price save failed. SKU not found.');
                }
              } catch (err) {
                formikActions.setStatus(err);
                message.error('Trip save failed!');
              } finally {
                formikActions.setSubmitting(false);
              }
            }}
            render={({ status }: any) => (
              <Layout>
                <TripForm locations={[trip.location]} disableLocation />
                {!!status && <Alert message={status.message} type="error" showIcon closable />}
              </Layout>
            )}
          />
        );
      }}
    />
  );
}

const prepareInitialValues = R.pipe<any, any, any, any, any, any, any, any, any, any>(
  L.remove('__typename'),
  L.remove('location'),
  L.remove(['skus', L.find(R.propEq('type', 'second_installment'))]),
  L.modify(['skus', L.elems, 'type'], R.concat(R.__ as any, 'Price')),
  L.modify(['skus', L.elems], R.pick(['type', 'price'])),
  L.modify(['skus', L.elems], (obj: any) => ({ [obj.type]: obj.price })),
  L.modify('skus', R.mergeAll),
  (obj: any) => ({ ...obj, ...obj.skus }),
  L.remove('skus')
);

export default Edit;
