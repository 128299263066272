import { Cascader, message, Modal } from 'antd';
import { CascaderOptionType } from 'antd/lib/cascader';
import { ApolloError } from 'apollo-client';
import { GraphQLError } from 'graphql';
import { DateTime } from 'luxon';
import * as L from 'partial.lenses';
import * as R from 'ramda';
import * as React from 'react';
import { Reducer, useCallback, useEffect, useReducer, useState } from 'react';
import {
  ILocation,
  ListLocationForChangeTripDocument,
  ListTripsForChangeTripDocument,
  useUpdateBookingForChangeTripMutation
} from '../../../generated/graphql';
import apolloClient from '../../../lib/apolloClient';

interface ChangeTripModalReducer extends Reducer<any, any> {}

function reducer(state: any, action: any) {
  switch (action.type) {
    case 'loadedLocations':
      return { options: action.payload.locations };
    case 'loadingLocation':
      return L.set(['options', L.find(R.whereEq({ value: action.payload.value })), 'loading'], true)(state);
    case 'loadedChildren':
      return L.set(
        ['options', L.find(R.whereEq({ value: action.payload.value })), 'children'],
        action.payload.children
      )(state);
    case 'loadedLocation':
      return L.set(['options', L.find(R.whereEq({ value: action.payload.value })), 'loading'], false)(state);
    default:
      throw new Error();
  }
}

interface Props {
  booking: any;
  visible?: boolean;

  onCancel?(result?: boolean): void;
}

function ChangeTripModal(props: Props) {
  const { booking, visible, onCancel } = props;
  const [updateBooking] = useUpdateBookingForChangeTripMutation();
  const [loading, setLoading] = useState(false);
  const [{ options }, dispatch] = useReducer<ChangeTripModalReducer>(reducer, { options: [] });

  useEffect(() => {
    apolloClient
      .query<{ locations: Array<ILocation> }>({ query: ListLocationForChangeTripDocument })
      .then(({ data }) => {
        const locations = R.map((location: ILocation) => ({
          value: location.locationId,
          label: location.title,
          isLeaf: false
        }))(data.locations);
        dispatch({ type: 'loadedLocations', payload: { locations } });
      })
      .catch(err => {
        console.error(err);
        (err as ApolloError).graphQLErrors.forEach((error: GraphQLError) => console.log(error.message));
      });
  }, []);

  const loadData = useCallback(async (selectedOptions?: Array<CascaderOptionType>) => {
    if (!selectedOptions) {
      return;
    }

    const targetOption = selectedOptions[selectedOptions.length - 1];
    dispatch({ type: 'loadingLocation', payload: { value: targetOption.value } });

    try {
      const { data } = await apolloClient.query({
        query: ListTripsForChangeTripDocument,
        variables: {
          filter: {
            locationId: targetOption.value,
            archived: false,
            disabled: false
          }
        }
      });

      const children = R.map((trip: any) => ({
        label: `${DateTime.fromSQL(trip.startDate).toLocaleString(DateTime.DATE_MED)} - ${DateTime.fromSQL(
          trip.endDate
        ).toLocaleString(DateTime.DATE_MED)} (${trip.seatsTaken}/${trip.maxSeats})`,
        value: trip.tripId,
        disabled: trip.seatsTaken >= trip.maxSeats
      }))(data.trips);

      dispatch({ type: 'loadedChildren', payload: { value: targetOption.value, children } });
    } catch (err) {
      console.error(err);
      (err as ApolloError).graphQLErrors.forEach((error: GraphQLError) => console.log(error.message));
    } finally {
      dispatch({ type: 'loadedLocation', payload: { value: targetOption.value } });
    }
  }, []);

  const onOk = useCallback(async () => {
    try {
      setLoading(true);
      await updateBooking({
        variables: {
          input: {
            bookingId: booking.bookingId,
            tripId: booking.tripId,
            returnCustomer: booking.returnCustomer,
            roomArrangement: booking.roomArrangement,
            status: 'PENDING',
            friendName: booking.friendName
          } as any
        }
      });
      message.success('Changed booking trip successfully.');
      if (onCancel) {
        onCancel(true);
      }
    } catch (err) {
      console.error(err);
      (err as ApolloError).graphQLErrors.forEach((error: GraphQLError) => message.error(error.message));
    } finally {
      setLoading(false);
    }
  }, [updateBooking, booking, onCancel]);

  return (
    <Modal
      title="Change Trip"
      visible={visible}
      confirmLoading={loading}
      onCancel={() => onCancel && onCancel()}
      onOk={onOk}
    >
      <Cascader
        style={{
          width: '100%'
        }}
        options={options}
        loadData={loadData}
        onChange={([locationId, tripId]) => {
          booking.tripId = tripId;
        }}
        changeOnSelect
        allowClear
      />
    </Modal>
  );
}

export default ChangeTripModal;
