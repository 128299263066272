import * as R from 'ramda';

export const formatPercentage = R.pipe<number, number, string>(
  R.defaultTo(0),
  new Intl.NumberFormat('en-US', {
    style: 'percent'
  }).format
);

export const formatDollars = R.pipe<number, number, number, string>(
  R.defaultTo(0),
  R.multiply(0.01),
  new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    maximumFractionDigits: 0,
    minimumFractionDigits: 0
  }).format
);
