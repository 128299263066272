import { Button } from 'antd';
import * as firebase from 'firebase/app';
import * as React from 'react';
import { Link } from 'react-router-dom';
import links from '../../lib/links';

function Navbar() {
  return (
    <section
      style={{
        display: 'flex',
        alignItems: 'center',
        padding: '0.875rem 0.625rem',
        borderBottom: '1px solid #e3e3e3'
      }}
    >
      <img alt="logo" src={require('../../assets/images/logo.png')} />
      <span style={{ flex: 1 }} />
      <Button style={{ color: '#669157' }} type="link">
        <Link to={links.locations()}>Locations</Link>
      </Button>
      <Button style={{ color: '#669157' }} type="link">
        <Link to={links.trips({ archived: false })}>Trips</Link>
      </Button>
      <Button style={{ color: '#669157' }} type="link">
        <Link to={links.coupons()}>Coupons</Link>
      </Button>
      <Button style={{ color: '#669157' }} type="link">
        <Link to={links.bookings()}>Bookings</Link>
      </Button>
      <Button style={{ color: '#669157' }} type="link">
        <Link to={links.settings()}>Settings</Link>
      </Button>
      <Button style={{ color: '#669157' }} type="link" onClick={() => firebase.auth().signOut()}>
        Logout
      </Button>
    </section>
  );
}

export default Navbar;
