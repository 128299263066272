import { message } from 'antd';
import { ApolloError } from 'apollo-client';
import { Formik } from 'formik';
import { GraphQLError } from 'graphql';
import gql from 'graphql-tag';
import * as React from 'react';
import { useCallback } from 'react';
import { useMutation } from 'react-apollo-hooks';
import { RouteComponentProps } from 'react-router';
import styled from 'styled-components';
import uuid from 'uuid/v4';
import BookingForm from '../../components/BookingForm/BookingForm';
import links from '../../lib/links';

const CREATE_BOOKING = gql`
  mutation createBooking($input: CreateBookingInput!) {
    createBooking(input: $input) {
      bookingId
    }
  }
`;

interface Props extends RouteComponentProps {}

function Create(props: Props) {
  const [createBooking] = useMutation<{ createBooking: { bookingId: string } }>(CREATE_BOOKING);

  const onSubmit = useCallback(
    async (input: any, formikActions: any) => {
      try {
        formikActions.setSubmitting(true);
        formikActions.setStatus(undefined);
        const { data } = await createBooking({ variables: { input } });
        message.success('Booking create successfully!');
        if (data) {
          props.history.push(links.editBooking(data.createBooking.bookingId));
        }
      } catch (err) {
        console.error(err);
        (err as ApolloError).graphQLErrors.forEach((error: GraphQLError) => {
          message.error(error.message);
        });
      } finally {
        formikActions.setSubmitting(false);
      }
    },
    [props.history, createBooking]
  );

  return (
    <Formik
      initialValues={{
        idempotencyKey: uuid(),
        returnCustomer: false,
        travelers: [{}]
      }}
      onSubmit={onSubmit}
      render={() => (
        <Layout>
          <BookingForm />
        </Layout>
      )}
    />
  );
}

const Layout = styled('section')`
  width: 100%;
  max-width: 1024px;
  margin: 0 auto;
`;

export default Create;
