import { Button, Popover, Radio, Select, Table, Tag, Tooltip, Typography } from 'antd';
import Search from 'antd/lib/input/Search';
import { RadioChangeEvent } from 'antd/lib/radio';
import FlexSearch from 'flexsearch';
import { DateTime } from 'luxon';
import * as R from 'ramda';
import * as RA from 'ramda-adjunct';
import * as React from 'react';
import { useQueryParam } from 'use-query-params';
import { BooleanParam, StringParam } from 'use-query-params/lib/params';
import AsyncComponent from '../../components/AsyncComponent/AsyncComponent';
import { ILocation, ITrip, useListTripsQuery } from '../../generated/graphql';
import links from '../../lib/links';
import { pickRegion } from '../../lib/utils';

const columns = [
  {
    title: 'ID',
    dataIndex: 'tripId',
    key: 'tripId'
  },
  {
    title: 'Country',
    dataIndex: 'trip.location.cntry.name',
    key: 'country',
    render: (text: string, trip: ITrip) => <span>{trip.location!.cntry!.name}</span>
  },
  {
    title: 'Location',
    dataIndex: 'trip.location.title',
    key: 'y',
    render: (text: string, trip: ITrip) => <span>{trip.location!.title}</span>
  },
  {
    title: 'Seats Available',
    dataIndex: '',
    key: 'v',
    render: (text: string, trip: ITrip) => (
      <span>
        {trip.seatsTaken}/{trip.maxSeats}
      </span>
    )
  },
  {
    title: 'Tags',
    dataIndex: 'tags',
    key: 'tags',
    render: (tags: Array<string>, trip: ITrip) => (
      <>
        <Tag color="blue">{pickRegion(trip.location)}</Tag>
        {R.map((tag: string) => (
          <Tag color="blue" key={tag}>
            {tag}
          </Tag>
        ))(tags)}
      </>
    )
  },
  {
    title: 'Start Date',
    dataIndex: 'startDate',
    key: 'startDate',
    render: (startDate: string) => <span>{DateTime.fromISO(startDate).toLocaleString(DateTime.DATE_MED)}</span>
  },
  {
    title: 'End Date',
    dataIndex: 'endDate',
    key: 'endDate',
    render: (endDate: string) => <span>{DateTime.fromISO(endDate).toLocaleString(DateTime.DATE_MED)}</span>
  },
  {
    title: 'Disabled',
    dataIndex: 'active',
    key: 'active',
    render: (val: boolean) => <span>{!val ? 'Yes' : 'No'}</span>
  },
  {
    title: 'Archived',
    dataIndex: 'archived',
    key: 'archived',
    render: (val: boolean) => <span>{val ? 'Yes' : 'No'}</span>
  },
  {
    title: 'Action',
    dataIndex: '',
    key: 'x',
    render: (text: string, trip: ITrip) => (
      <section>
        <Tooltip title="Edit">
          <Button href={links.editTrip(trip.tripId)} icon="form" type="link" />
        </Tooltip>
        &nbsp;
        <Tooltip title="Go to checkout">
          <Button href={links.checkout(trip.locationId)} target="_blank" icon="global" type="link" />
        </Tooltip>
        &nbsp;
      </section>
    )
  }
];

function List() {
  const [locationId, setLocationId] = useQueryParam('location_id', StringParam);
  const [archived, setArchived] = useQueryParam('archived', BooleanParam);
  const [disabled, setDisabled] = useQueryParam('disabled', BooleanParam);
  const [search = '', setSearch] = useQueryParam('search', StringParam);
  const query = useListTripsQuery({
    variables: {
      filter: {
        locationId,
        archived,
        disabled
      }
    }
  });

  return (
    <AsyncComponent
      {...query}
      render={({ trips, locations }) => {
        const index = FlexSearch.create({
          doc: {
            id: 'tripId',
            field: [
              'tripId',
              'startDate',
              'endDate',
              'locationId',
              'active',
              'location:title',
              'location:cntry:name',
              'location:cntry:region',
              'location:cntry:subRegion',
              'location:cntry:intermediateRegion'
            ]
          }
        } as any);

        index.add(trips);

        const dataSource: any = RA.isNotEmpty(search) ? index.search(search) : trips;

        return (
          <>
            <Button
              type="primary"
              htmlType="button"
              href={links.createTrip()}
              style={{
                marginBottom: 16
              }}
            >
              Create Trip
            </Button>
            &nbsp;
            <Popover
              content={
                <section
                  style={{
                    display: 'flex',
                    flexDirection: 'column'
                  }}
                >
                  <Select
                    style={{ width: 250 }}
                    defaultValue={locationId}
                    onChange={(val: string) => setLocationId(val)}
                    placeholder="Select Location"
                  >
                    <Select.Option value="">&nbsp;</Select.Option>
                    {R.map((location: ILocation) => (
                      <Select.Option key={location.locationId} value={location.locationId}>
                        {location.title}
                      </Select.Option>
                    ))(locations)}
                  </Select>
                  &nbsp;
                  <div>
                    <Typography.Text strong>Disabled</Typography.Text>
                    &nbsp;
                    <Radio.Group
                      buttonStyle="solid"
                      onChange={(event: RadioChangeEvent) => setDisabled(event.target.value)}
                      defaultValue={disabled}
                    >
                      <Radio.Button>All</Radio.Button>
                      <Radio.Button value={true}>Yes</Radio.Button>
                      <Radio.Button value={false}>No</Radio.Button>
                    </Radio.Group>
                  </div>
                  &nbsp;
                  <div>
                    <Typography.Text strong>Archived</Typography.Text>
                    &nbsp;
                    <Radio.Group
                      buttonStyle="solid"
                      onChange={(event: RadioChangeEvent) => setArchived(event.target.value)}
                      defaultValue={archived}
                    >
                      <Radio.Button>All</Radio.Button>
                      <Radio.Button value={true}>Yes</Radio.Button>
                      <Radio.Button value={false}>No</Radio.Button>
                    </Radio.Group>
                  </div>
                </section>
              }
              trigger="click"
              placement="bottomRight"
            >
              <Button type="default" htmlType="button">
                Filter
              </Button>
            </Popover>
            <Search
              name="search"
              size="large"
              onSearch={value => setSearch(value)}
              style={{
                marginBottom: '1rem'
              }}
            />
            <Table columns={columns} dataSource={dataSource} rowKey="tripId" bordered />
          </>
        );
      }}
    />
  );
}

export default List;
