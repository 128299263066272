import { Timeline } from 'antd';
import { DateTime } from 'luxon';
import * as L from 'partial.lenses';
import * as R from 'ramda';
import * as React from 'react';
import Map from '../../components/Map/Map';
import { IBookingChangelog } from '../../generated/graphql';

interface Props {
  data: Array<IBookingChangelog>;
}

function ChangelogTimeline(props: Props) {
  const { data } = props;

  const prettyPrint = (obj: object) => JSON.stringify(obj, null, 2);

  const formatData = R.pipe(JSON.parse, prettyPrint);

  const mapActionText = (action: string) => {
    switch (action) {
      case 'I':
        return 'Created';
      case 'U':
        return 'Updated';
      case 'D':
        return 'Deleted';
      case 'T':
        return 'Truncated';
      default:
        return action;
    }
  };

  const renderData = ({ action, rowData, changedFields }: IBookingChangelog) => {
    switch (action) {
      case 'Created':
        return <pre>{rowData}</pre>;
      case 'Updated':
        return <pre>{changedFields}</pre>;
      default:
        return null;
    }
  };

  const mappedData: Array<IBookingChangelog> = R.pipe(
    L.modify([L.elems, 'action'], mapActionText),
    L.modify([L.elems, 'createTime'], (createTime: string) =>
      DateTime.fromISO(createTime).toLocaleString(DateTime.DATETIME_SHORT)
    ),
    L.modify([L.elems, 'rowData'], formatData),
    L.modify([L.elems, 'changedFields'], formatData)
  )(
    // @ts-ignore
    data
  );

  return (
    <Timeline>
      <Map
        data={mappedData}
        render={(audit: IBookingChangelog) => (
          <Timeline.Item key={audit.eventId}>
            <p>
              {audit.action} {audit.createTime}
            </p>
            {renderData(audit)}
          </Timeline.Item>
        )}
      />
    </Timeline>
  );
}

export default ChangelogTimeline;
