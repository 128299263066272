import { Checkbox, DatePicker, Form, Input, InputNumber, Select } from 'antd';
import moment from 'moment';
import * as R from 'ramda';
import * as React from 'react';
import Alert from '../../../components/Alert';
import {
  ILocation,
  ITrip,
  useListLocationsCouponFormLazyQuery,
  useListTripsCouponFormLazyQuery
} from '../../../generated/graphql';
import CouponRules from './CouponRules';
import { useForm } from './Form';

const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 4 }
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 20 }
  }
};

interface Props {
  disableId?: boolean;
  locations?: Array<ILocation>;
  trips?: Array<ITrip>;
}

function CouponForm(props: Props) {
  const { disableId, locations = [], trips = [] } = props;
  const [listLocations, listLocationsResponse] = useListLocationsCouponFormLazyQuery();
  const [listTrips, listTripsResponse] = useListTripsCouponFormLazyQuery();
  const formik = useForm();

  const values = formik.values ?? {};

  const renderTripOptionText = (trip: ITrip) => `${trip.location!.title} [${trip.startDate}]`;

  return (
    <>
      <Form.Item {...formItemLayout} label="Coupon">
        <Input name="couponId" onChange={formik.handleChange} value={values.couponId} disabled={disableId} />
      </Form.Item>

      <Form.Item {...formItemLayout} label="Description">
        <Input name="description" onChange={formik.handleChange} value={values.description} />
      </Form.Item>

      <Form.Item {...formItemLayout} label="Start Time">
        <DatePicker
          style={{
            minWidth: '300px'
          }}
          showTime={{
            use12Hours: true
          }}
          name="startTime"
          format="MMM Do YYYY, h:mm:ssa"
          onChange={(date: moment.Moment | null) =>
            formik.setFieldValue('startTime', date ? date.toISOString() : undefined)
          }
          value={values.startTime ? moment(values.startTime) : undefined}
        />
      </Form.Item>

      <Form.Item {...formItemLayout} label="End Time">
        <DatePicker
          style={{
            minWidth: '300px'
          }}
          showTime={{
            use12Hours: true
          }}
          name="endTime"
          format="MMM Do YYYY, h:mm:ssa"
          onChange={(date: moment.Moment | null) =>
            formik.setFieldValue('endTime', date ? date.toISOString() : undefined)
          }
          value={values.endTime ? moment(values.endTime) : undefined}
        />
      </Form.Item>

      <Form.Item {...formItemLayout} label="Max Redemptions">
        <InputNumber
          name="maxRedemptions"
          onChange={(value: number | undefined) => formik.setFieldValue('maxRedemptions', value)}
          value={values.maxRedemptions}
          min={0}
        />
      </Form.Item>

      <Form.Item {...formItemLayout} label="Global">
        <Checkbox name="global" onChange={formik.handleChange} defaultChecked={values.global} checked={values.global} />
      </Form.Item>

      <Form.Item {...formItemLayout} label="Disabled">
        <Checkbox
          name="disabled"
          onChange={formik.handleChange}
          defaultChecked={values.disabled}
          checked={values.disabled}
        />
      </Form.Item>

      <Form.Item {...formItemLayout} label="Locations">
        <Select
          showArrow
          loading={listLocationsResponse.loading}
          mode="tags"
          placeholder="Apply to specific locations"
          optionFilterProp="children"
          filterOption={(input, option) =>
            (option as any)?.props?.children?.toLowerCase().indexOf(input.toLowerCase()) >= 0
          }
          value={values.locationIds}
          onFocus={() => listLocations()}
          onChange={(id: string) => formik.setFieldValue('locationIds', id)}
          size="large"
        >
          {R.map((location: ILocation) => (
            <Select.Option key={location.locationId} value={location.locationId}>
              {location.title}
            </Select.Option>
          ))(listLocationsResponse.data?.locations ?? (locations as any))}
        </Select>
      </Form.Item>

      <Form.Item {...formItemLayout} label="Trips">
        <Select
          showArrow
          loading={listTripsResponse.loading}
          mode="tags"
          placeholder="Apply to specific trips"
          optionFilterProp="children"
          filterOption={(input, option) =>
            (option as any)?.props?.children?.toLowerCase().indexOf(input.toLowerCase()) >= 0
          }
          value={values.tripIds}
          onFocus={() => listTrips({ variables: { filter: { archived: false } } })}
          onChange={(id: string) => formik.setFieldValue('tripIds', id)}
          size="large"
        >
          {R.map((trip: ITrip) => (
            <Select.Option key={trip.tripId} value={trip.tripId}>
              {renderTripOptionText(trip)}
            </Select.Option>
          ))(listTripsResponse.data?.trips ?? (trips as any))}
        </Select>
      </Form.Item>

      <Form.Item {...formItemLayout} label="-">
        <CouponRules />
      </Form.Item>

      <Alert error={listLocationsResponse.error} />

      <Alert error={listTripsResponse.error} />
    </>
  );
}

export default CouponForm;
