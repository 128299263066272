import { CascaderOptionType } from 'antd/lib/cascader';
import * as R from 'ramda';
import * as React from 'react';
import { CSSProperties } from 'react';
import AsyncComponent from '../../../components/AsyncComponent/AsyncComponent';
import { ILocation, useListLocationForSelectTripQuery } from '../../../generated/graphql';
import SelectCascader from './SelectCascader';

interface Props {
  style?: CSSProperties;

  onChange?(value: Array<string>, selectedOptions?: Array<CascaderOptionType>): void;
}

function SelectTrip(props: Props) {
  const { style, onChange } = props;
  const query = useListLocationForSelectTripQuery();

  return (
    <AsyncComponent
      {...query}
      render={({ locations }) => {
        const options = R.map((location: ILocation) => ({
          value: location.locationId,
          label: location.title,
          isLeaf: false
        }))(locations);

        return <SelectCascader style={style} options={options} onChange={onChange} />;
      }}
    />
  );
}

export default SelectTrip;
