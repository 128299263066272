import * as firebase from 'firebase/app';
import { useEffect, useState } from 'react';

function useAuth() {
  const initialState = sessionStorage.getItem('isAuthenticated');
  const [isAuthenticated, setIsAuthenticated] = useState(Boolean(initialState) || false);

  useEffect(() => {
    const unsubscribe = firebase.auth().onAuthStateChanged(user => {
      const value = !!user;
      sessionStorage.setItem('isAuthenticated', String(value));
      setIsAuthenticated(value);
    });

    return () => {
      unsubscribe();
    };
  }, []);

  return {
    isAuthenticated
  };
}

export default useAuth;
