import { Form, Input, Modal, Typography } from 'antd';
import * as L from 'partial.lenses';
import * as React from 'react';
import { useForm } from 'react-hook-form';
import { RHFInput } from 'react-hook-form-input';
import { GetBookingDocument, useCreateOrderPriceAdjustmentMutation } from '../../../generated/graphql';
import * as formatter from '../../../lib/formatter';
import * as parser from '../../../lib/parser';

interface Props {
  order: any;
  visible?: boolean;

  onCancel?(): void;
}

function OrderPriceAdjustmentModal(props: Props) {
  const { order, visible, onCancel } = props;
  const { register, handleSubmit, errors, setValue } = useForm();
  const [createOrderPriceAdjustment, query] = useCreateOrderPriceAdjustmentMutation();

  const closeModal = () => onCancel && onCancel();

  const onSubmit = async (data: any) => {
    await createOrderPriceAdjustment({
      variables: {
        input: {
          orderId: order.orderId,
          amount: parser.parseCurrency(data.amount),
          note: data.note
        }
      },
      update: (proxy, mutationResult) => {
        try {
          const options = {
            query: GetBookingDocument,
            variables: {
              bookingId: order.bookingId
            }
          };
          proxy.writeQuery({
            ...options,
            data: L.set(
              ['booking', 'order', 'priceAdjustments', L.appendTo],
              mutationResult.data?.createOrderPriceAdjustment,
              proxy.readQuery(options)
            )
          });
        } catch (err) {
          console.error(err);
        }
      }
    });
    closeModal();
  };

  return (
    <Modal
      title="Order Price Adjustment"
      visible={visible}
      confirmLoading={query.loading}
      onCancel={() => closeModal()}
      onOk={handleSubmit(onSubmit)}
    >
      <Form onSubmit={handleSubmit(onSubmit)}>
        <Form.Item label="Amount">
          <RHFInput
            as={<Input />}
            rules={{ required: true }}
            name="amount"
            register={register}
            setValue={setValue}
            onChangeEvent={([e]: any) => {
              const parsed = parser.parseCurrency(e.target.value);
              const value = formatter.formatDollars(parsed);
              return { value };
            }}
          />

          {errors.amount && <Typography.Text type="danger">Amount is required</Typography.Text>}
        </Form.Item>

        <Form.Item label="Note">
          <RHFInput
            as={<Input.TextArea rows={4} />}
            rules={{ required: true }}
            name="note"
            register={register}
            setValue={setValue}
          />

          {errors.note && <Typography.Text type="danger">Note is required</Typography.Text>}
        </Form.Item>
      </Form>
    </Modal>
  );
}

export default OrderPriceAdjustmentModal;
