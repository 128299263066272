import { Button, Form, message, Popconfirm } from 'antd';
import { useFormik } from 'formik';
import { DateTime } from 'luxon';
import * as R from 'ramda';
import * as React from 'react';
import { RouteComponentProps } from 'react-router';
import Alert from '../../components/Alert';
import Layout from '../../components/Layout';
import LoadingSpinner from '../../components/LoadingSpinner/LoadingSpinner';
import { useDeleteCouponMutation, useGetCouponQuery, useUpdateCouponMutation } from '../../generated/graphql';
import CouponForm from './components/CouponForm';
import { FormProvider } from './components/Form';

const formItemLayout = {
  wrapperCol: {
    xs: {
      span: 24,
      offset: 0
    },
    sm: {
      span: 20,
      offset: 4
    }
  }
};

interface Props extends RouteComponentProps<{ couponId: string }> {}

function Edit(props: Props) {
  const { couponId } = props.match.params;
  const query = useGetCouponQuery({ variables: { couponId } });
  const [updateCoupon, { loading, error }] = useUpdateCouponMutation();
  const [deleteCoupon, { loading: deleting }] = useDeleteCouponMutation();
  const removeTypenameField = R.omit(['__typename']);

  const coupon = query.data?.coupon;

  const formik = useFormik<any>({
    initialValues: {
      ...coupon
    },
    enableReinitialize: true,
    onSubmit: async values => {
      try {
        const input: any = R.pipe(
          removeTypenameField,
          R.omit(['deletedTime', 'locations', 'trips']),
          R.evolve({
            rules: R.map(
              R.pipe(
                removeTypenameField,
                R.evolve({
                  conditions: R.map(removeTypenameField)
                })
              )
            )
          })
        )(values);

        await updateCoupon({ variables: { input } });
        message.success('Coupon saved successfully!');
      } catch (err) {
        console.error(err);
        message.error('Coupon save failed!');
      }
    }
  });

  const confirmDeleteCoupon = async (id: string) => {
    try {
      await deleteCoupon({ variables: { couponId: id } });
      message.success('Deleted coupon!');
      await query.refetch();
    } catch (err) {
      console.error(err);
      message.error('Delete coupon failed!');
    }
  };

  if (query.loading) {
    return <LoadingSpinner />;
  }

  if (query.error) {
    return <div>{query.error.message}</div>;
  }

  return (
    <Layout>
      <FormProvider value={formik}>
        <form onSubmit={formik.handleSubmit}>
          <CouponForm disableId locations={coupon?.locations as any} trips={coupon?.trips as any} />

          <Form.Item {...formItemLayout}>
            <Button type="primary" htmlType="submit" disabled={deleting || !!coupon!.deletedTime} loading={loading}>
              Save
            </Button>
            &nbsp; &nbsp;
            <Popconfirm
              title="Are you sure?"
              okText="Yes"
              cancelText="No"
              onConfirm={() => confirmDeleteCoupon(coupon!.couponId)}
            >
              <Button type="danger" htmlType="button" disabled={loading || !!coupon!.deletedTime} loading={deleting}>
                Delete
              </Button>
            </Popconfirm>
            &nbsp; &nbsp;
            {!!coupon!.deletedTime && (
              <div>Deleted on {DateTime.fromISO(coupon!.deletedTime).toLocaleString(DateTime.DATETIME_SHORT)}</div>
            )}
          </Form.Item>

          <Alert error={error} />
        </form>
      </FormProvider>
    </Layout>
  );
}

export default Edit;
