import { FormikProps } from 'formik';
import * as React from 'react';
import { ReactNode, useContext } from 'react';

export const FormContext = React.createContext<FormikProps<any> | undefined>(undefined);

interface Props {
  children: ReactNode;
  value: any;
}

export const FormProvider = (props: Props) => {
  const { children, value } = props;

  return <FormContext.Provider value={value}>{children}</FormContext.Provider>;
};

export const useForm = () => {
  const formik = useContext(FormContext);

  if (!formik) {
    throw new Error('formik undefined');
  }

  return formik;
};
