import buildQueryParams from './buildQueryParams';
import config from './config';

interface ITripsQuery {
  locationId?: string;
  archived?: boolean;
}

export default {
  login: () => '/login',
  locations: () => '/locations',
  createLocation: () => '/locations/create',
  editLocation: (id: string) => `/locations/${id}/edit`,
  trips: (query: ITripsQuery) => `/trips?${buildQueryParams(query)}`,
  createTrip: () => '/trips/create',
  editTrip: (id: string) => `/trips/${id}/edit`,
  coupons: () => '/coupons',
  createCoupon: () => '/coupons/create',
  editCoupon: (id: string) => `/coupons/${id}/edit`,
  bookings: () => '/bookings',
  createBooking: () => '/bookings/create',
  editBooking: (id: string) => `/bookings/${id}/edit`,
  settings: () => '/settings',
  checkout: (id: string) => `${config.checkoutURL}/${id}`
};
