import gql from 'graphql-tag';
import * as ApolloReactCommon from '@apollo/react-common';
import * as ApolloReactHooks from '@apollo/react-hooks';
export type Maybe<T> = T | null;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string,
  String: string,
  Boolean: boolean,
  Int: number,
  Float: number,
  Upload: any,
};

export type IAddress = {
  line1?: Maybe<Scalars['String']>,
  line2?: Maybe<Scalars['String']>,
  city?: Maybe<Scalars['String']>,
  region?: Maybe<Scalars['String']>,
  postalCode?: Maybe<Scalars['String']>,
  country?: Maybe<Scalars['String']>,
};

export type IAddressInput = {
  line1: Scalars['String'],
  line2?: Maybe<Scalars['String']>,
  city: Scalars['String'],
  region: Scalars['String'],
  postalCode: Scalars['String'],
  country: Scalars['String'],
};

export type IAddSheetInput = {
  locationId: Scalars['String'],
  tripId: Scalars['String'],
};

export type IAppendBookingToSpreadsheet = {
  bookingId: Scalars['ID'],
};

export type IApplyCouponInput = {
  couponId: Scalars['String'],
  sku: Scalars['String'],
};

export type IApplyCouponResponse = {
  discountAmount?: Maybe<Scalars['Float']>,
};

export type IBooking = {
  travelers?: Maybe<Array<ITraveler>>,
  changelogs?: Maybe<Array<IBookingChangelog>>,
  bookingId: Scalars['ID'],
  tripId: Scalars['ID'],
  returnCustomer?: Maybe<Scalars['Boolean']>,
  roomArrangement?: Maybe<IRoomArrangement>,
  friendName?: Maybe<Scalars['String']>,
  createTime?: Maybe<Scalars['String']>,
  updateTime?: Maybe<Scalars['String']>,
  status?: Maybe<IBookingStatus>,
  travelerCount?: Maybe<Scalars['Int']>,
  manuallyCreated?: Maybe<Scalars['Boolean']>,
  order?: Maybe<IOrder>,
  trip?: Maybe<ITrip>,
};

export type IBookingChangelog = {
  eventId: Scalars['ID'],
  action?: Maybe<Scalars['String']>,
  rowData?: Maybe<Scalars['String']>,
  changedFields?: Maybe<Scalars['String']>,
  createTime?: Maybe<Scalars['String']>,
};

export type IBookingOrder = {
  bookingId?: Maybe<Scalars['ID']>,
  orderId?: Maybe<Scalars['ID']>,
  amount?: Maybe<Scalars['Float']>,
  sku?: Maybe<Scalars['String']>,
  createTime?: Maybe<Scalars['String']>,
  updateTime?: Maybe<Scalars['String']>,
};

export type IBookingPage = {
  nextPageToken?: Maybe<Scalars['String']>,
  bookings?: Maybe<Array<IBooking>>,
};

export enum IBookingStatus {
  Pending = 'PENDING',
  Approved = 'APPROVED',
  CancelledRefunded = 'CANCELLED_REFUNDED',
  CancelledHeld = 'CANCELLED_HELD'
}

export enum ICacheControlScope {
  Public = 'PUBLIC',
  Private = 'PRIVATE'
}

export type ICountry = {
  code?: Maybe<Scalars['String']>,
  alpha2?: Maybe<Scalars['String']>,
  alpha3?: Maybe<Scalars['String']>,
  name?: Maybe<Scalars['String']>,
  region?: Maybe<Scalars['String']>,
  subRegion?: Maybe<Scalars['String']>,
  intermediateRegion?: Maybe<Scalars['String']>,
  countryCode?: Maybe<Scalars['String']>,
  regionCode?: Maybe<Scalars['String']>,
  subRegionCode?: Maybe<Scalars['String']>,
  intermediateRegionCode?: Maybe<Scalars['String']>,
};

export type ICoupon = {
  couponId: Scalars['ID'],
  description?: Maybe<Scalars['String']>,
  startTime?: Maybe<Scalars['String']>,
  endTime?: Maybe<Scalars['String']>,
  maxRedemptions?: Maybe<Scalars['Int']>,
  global?: Maybe<Scalars['Boolean']>,
  disabled?: Maybe<Scalars['Boolean']>,
  rules?: Maybe<Array<Maybe<ICouponRule>>>,
  locationIds?: Maybe<Array<Maybe<Scalars['ID']>>>,
  tripIds?: Maybe<Array<Maybe<Scalars['ID']>>>,
  createTime?: Maybe<Scalars['String']>,
  updateTime?: Maybe<Scalars['String']>,
  deletedTime?: Maybe<Scalars['String']>,
  locations?: Maybe<Array<Maybe<ILocation>>>,
  trips?: Maybe<Array<Maybe<ITrip>>>,
};

export type ICouponFilter = {
  global?: Maybe<Scalars['Boolean']>,
};

export type ICouponRule = {
  type?: Maybe<ICouponRuleType>,
  value?: Maybe<Scalars['Float']>,
  conditions?: Maybe<Array<Maybe<ICouponRuleCondition>>>,
};

export type ICouponRuleCondition = {
  field?: Maybe<Scalars['String']>,
  operator?: Maybe<Scalars['String']>,
  value?: Maybe<Scalars['Float']>,
};

export type ICouponRuleConditionInput = {
  field: Scalars['String'],
  operator: Scalars['String'],
  value: Scalars['Float'],
};

export type ICouponRuleInput = {
  type: ICouponRuleType,
  value: Scalars['Float'],
  conditions?: Maybe<Array<Maybe<ICouponRuleConditionInput>>>,
};

export enum ICouponRuleType {
  Amount = 'amount',
  Percentage = 'percentage'
}

export type ICreateBookingInput = {
  tripId: Scalars['ID'],
  returnCustomer?: Maybe<Scalars['Boolean']>,
  travelers?: Maybe<Array<Maybe<ITravelerInput>>>,
  roomArrangement: IRoomArrangement,
  friendName?: Maybe<Scalars['String']>,
  status?: Maybe<IBookingStatus>,
  idempotencyKey?: Maybe<Scalars['String']>,
  acceptTerms?: Maybe<Scalars['Boolean']>,
  acceptCancellation?: Maybe<Scalars['Boolean']>,
  order?: Maybe<IOrderInput>,
};

export type ICreateCouponInput = {
  couponId: Scalars['ID'],
  description?: Maybe<Scalars['String']>,
  startTime?: Maybe<Scalars['String']>,
  endTime?: Maybe<Scalars['String']>,
  maxRedemptions?: Maybe<Scalars['Int']>,
  global?: Maybe<Scalars['Boolean']>,
  disabled?: Maybe<Scalars['Boolean']>,
  rules?: Maybe<Array<Maybe<ICouponRuleInput>>>,
  locationIds?: Maybe<Array<Maybe<Scalars['String']>>>,
  tripIds?: Maybe<Array<Maybe<Scalars['String']>>>,
};

export type ICreateLocationInput = {
  country: Scalars['String'],
  title: Scalars['String'],
  description?: Maybe<Scalars['String']>,
  image?: Maybe<Scalars['String']>,
  included?: Maybe<Array<Maybe<Scalars['String']>>>,
  notIncluded?: Maybe<Array<Maybe<Scalars['String']>>>,
};

export type ICreateOrderPriceAdjustmentInput = {
  orderId: Scalars['ID'],
  amount: Scalars['Int'],
  note: Scalars['String'],
};

export type ICreateTripInput = {
  locationId: Scalars['ID'],
  startDate: Scalars['String'],
  endDate: Scalars['String'],
  maxSeats: Scalars['Int'],
  earlyPrice: Scalars['Int'],
  depositPrice?: Maybe<Scalars['Int']>,
  fullPrice: Scalars['Int'],
  detailsUrl?: Maybe<Scalars['String']>,
  active?: Maybe<Scalars['Boolean']>,
  tags?: Maybe<Array<Maybe<Scalars['String']>>>,
};

export enum IGender {
  Male = 'male',
  Female = 'female'
}

export type IGetOrderPreviewInput = {
  quantity: Scalars['Int'],
  sku: Scalars['ID'],
  couponId?: Maybe<Scalars['ID']>,
};

export type ILinkSalesDumpSpreadsheetInput = {
  spreadsheetId: Scalars['String'],
};

export type ILocation = {
  coupons?: Maybe<Array<Maybe<ICoupon>>>,
  locationId: Scalars['ID'],
  country?: Maybe<Scalars['String']>,
  title?: Maybe<Scalars['String']>,
  description?: Maybe<Scalars['String']>,
  image?: Maybe<Scalars['String']>,
  included?: Maybe<Array<Maybe<Scalars['String']>>>,
  notIncluded?: Maybe<Array<Maybe<Scalars['String']>>>,
  createTime?: Maybe<Scalars['String']>,
  updateTime?: Maybe<Scalars['String']>,
  cntry?: Maybe<ICountry>,
  spreadsheet?: Maybe<ISpreadsheet>,
  trips?: Maybe<Array<Maybe<ITrip>>>,
};

export type IMutation = {
  createBooking?: Maybe<IBooking>,
  updateBooking?: Maybe<IBooking>,
  updateTraveler?: Maybe<ITraveler>,
  createCoupon?: Maybe<ICoupon>,
  updateCoupon?: Maybe<ICoupon>,
  deleteCoupon: Scalars['Boolean'],
  createLocation?: Maybe<ILocation>,
  deleteLocation?: Maybe<Scalars['Boolean']>,
  updateLocation?: Maybe<ILocation>,
  applyCoupon?: Maybe<IApplyCouponResponse>,
  getOrderPreview?: Maybe<IOrderPreview>,
  updateTripSKU?: Maybe<ITripSku>,
  createOrderPriceAdjustment?: Maybe<IOrderPriceAdjustment>,
  linkSalesDumpSpreadsheet?: Maybe<Scalars['Boolean']>,
  addSheet?: Maybe<Scalars['Boolean']>,
  shareSpreadsheet?: Maybe<Scalars['Boolean']>,
  appendBookingToSpreadsheet?: Maybe<Scalars['Boolean']>,
  createTrip?: Maybe<ITrip>,
  updateTrip?: Maybe<ITrip>,
  updatePolicy: Scalars['Boolean'],
};


export type IMutationCreateBookingArgs = {
  input: ICreateBookingInput
};


export type IMutationUpdateBookingArgs = {
  input: IUpdateBookingInput
};


export type IMutationUpdateTravelerArgs = {
  input: IUpdateTravelerInput
};


export type IMutationCreateCouponArgs = {
  input: ICreateCouponInput
};


export type IMutationUpdateCouponArgs = {
  input: IUpdateCouponInput
};


export type IMutationDeleteCouponArgs = {
  couponId: Scalars['ID']
};


export type IMutationCreateLocationArgs = {
  input: ICreateLocationInput
};


export type IMutationDeleteLocationArgs = {
  locationId: Scalars['ID']
};


export type IMutationUpdateLocationArgs = {
  input?: Maybe<IUpdateLocationInput>
};


export type IMutationApplyCouponArgs = {
  input: IApplyCouponInput
};


export type IMutationGetOrderPreviewArgs = {
  input: IGetOrderPreviewInput
};


export type IMutationUpdateTripSkuArgs = {
  input: IUpdateTripSkuInput
};


export type IMutationCreateOrderPriceAdjustmentArgs = {
  input: ICreateOrderPriceAdjustmentInput
};


export type IMutationLinkSalesDumpSpreadsheetArgs = {
  input?: Maybe<ILinkSalesDumpSpreadsheetInput>
};


export type IMutationAddSheetArgs = {
  input: IAddSheetInput
};


export type IMutationShareSpreadsheetArgs = {
  input: IShareSpreadsheetInput
};


export type IMutationAppendBookingToSpreadsheetArgs = {
  input: IAppendBookingToSpreadsheet
};


export type IMutationCreateTripArgs = {
  input: ICreateTripInput
};


export type IMutationUpdateTripArgs = {
  input: IUpdateTripInput
};


export type IMutationUpdatePolicyArgs = {
  input: IUpdatePolicyInput
};

export type IOrder = {
  details?: Maybe<IOrderDetails>,
  couponIds?: Maybe<Array<Maybe<Scalars['ID']>>>,
  priceAdjustments?: Maybe<Array<Maybe<IOrderPriceAdjustment>>>,
  bookingId?: Maybe<Scalars['ID']>,
  orderId?: Maybe<Scalars['ID']>,
  createTime?: Maybe<Scalars['String']>,
  updateTime?: Maybe<Scalars['String']>,
  sku?: Maybe<Scalars['String']>,
  referralCode?: Maybe<Scalars['String']>,
  chargeId?: Maybe<Scalars['ID']>,
  amount?: Maybe<Scalars['Int']>,
};

export type IOrderCoupon = {
  orderId?: Maybe<Scalars['ID']>,
  couponId?: Maybe<Scalars['ID']>,
  amount?: Maybe<Scalars['String']>,
};

export type IOrderDetails = {
  orderId?: Maybe<Scalars['ID']>,
  amount?: Maybe<Scalars['Int']>,
};

export type IOrderInput = {
  token: Scalars['String'],
  sku: Scalars['String'],
  couponId?: Maybe<Scalars['String']>,
};

export type IOrderItemPreview = {
  type?: Maybe<Scalars['String']>,
  amount?: Maybe<Scalars['Int']>,
  description?: Maybe<Scalars['String']>,
  parent?: Maybe<Scalars['String']>,
  currency?: Maybe<Scalars['String']>,
  quantity?: Maybe<Scalars['Int']>,
  global?: Maybe<Scalars['Boolean']>,
};

export type IOrderPreview = {
  amount?: Maybe<Scalars['Int']>,
  items?: Maybe<Array<Maybe<IOrderItemPreview>>>,
};

export type IOrderPriceAdjustment = {
  id: Scalars['ID'],
  orderId?: Maybe<Scalars['ID']>,
  amount?: Maybe<Scalars['Int']>,
  note?: Maybe<Scalars['String']>,
  createTime?: Maybe<Scalars['String']>,
  updateTime?: Maybe<Scalars['String']>,
};

export type IPassport = {
  number?: Maybe<Scalars['String']>,
  expiration?: Maybe<Scalars['String']>,
  country?: Maybe<Scalars['String']>,
};

export type IPassportInput = {
  number: Scalars['String'],
  expiration: Scalars['String'],
  country: Scalars['String'],
};

export type IQuery = {
  bookingPage?: Maybe<IBookingPage>,
  booking?: Maybe<IBooking>,
  countBooking?: Maybe<Scalars['Int']>,
  countTraveler?: Maybe<Scalars['Int']>,
  bookingChangelogs?: Maybe<Array<Maybe<IBookingChangelog>>>,
  coupons?: Maybe<Array<Maybe<ICoupon>>>,
  coupon?: Maybe<ICoupon>,
  locations?: Maybe<Array<Maybe<ILocation>>>,
  location?: Maybe<ILocation>,
  listTripSKUs?: Maybe<Array<Maybe<ITripSku>>>,
  getBookingOrder?: Maybe<IBookingOrder>,
  getTripProduct?: Maybe<Scalars['String']>,
  listOrderCoupons?: Maybe<Array<Maybe<Scalars['ID']>>>,
  orderCouponsV2?: Maybe<Array<Maybe<IOrderCoupon>>>,
  order?: Maybe<IOrder>,
  orderDetails?: Maybe<IOrderDetails>,
  orderPriceAdjustments?: Maybe<Array<Maybe<IOrderPriceAdjustment>>>,
  spreadsheet?: Maybe<ISpreadsheet>,
  trips?: Maybe<Array<Maybe<ITrip>>>,
  trip?: Maybe<ITrip>,
  policy: Scalars['String'],
};


export type IQueryBookingPageArgs = {
  pageToken?: Maybe<Scalars['String']>,
  pageSize?: Maybe<Scalars['Int']>,
  query?: Maybe<Scalars['String']>
};


export type IQueryBookingArgs = {
  bookingId: Scalars['ID']
};


export type IQueryCountBookingArgs = {
  tripId: Scalars['ID'],
  status?: Maybe<Array<Maybe<IBookingStatus>>>
};


export type IQueryCountTravelerArgs = {
  tripId: Scalars['ID'],
  status?: Maybe<Array<Maybe<IBookingStatus>>>
};


export type IQueryBookingChangelogsArgs = {
  bookingId: Scalars['ID']
};


export type IQueryCouponsArgs = {
  filter?: Maybe<ICouponFilter>
};


export type IQueryCouponArgs = {
  couponId: Scalars['ID']
};


export type IQueryLocationArgs = {
  locationId: Scalars['ID']
};


export type IQueryListTripSkUsArgs = {
  tripId: Scalars['ID']
};


export type IQueryGetBookingOrderArgs = {
  bookingId: Scalars['ID']
};


export type IQueryGetTripProductArgs = {
  tripId: Scalars['ID']
};


export type IQueryListOrderCouponsArgs = {
  orderId: Scalars['ID']
};


export type IQueryOrderCouponsV2Args = {
  orderId: Scalars['ID']
};


export type IQueryOrderArgs = {
  orderId: Scalars['ID']
};


export type IQueryOrderDetailsArgs = {
  orderId: Scalars['ID']
};


export type IQueryOrderPriceAdjustmentsArgs = {
  orderId: Scalars['ID']
};


export type IQuerySpreadsheetArgs = {
  locationId: Scalars['String']
};


export type IQueryTripsArgs = {
  filter?: Maybe<ITripsFilter>
};


export type IQueryTripArgs = {
  tripId: Scalars['ID']
};

export enum IRoomArrangement {
  Solo = 'solo',
  Friend = 'friend',
  Couple = 'couple'
}

export type IShareSpreadsheetInput = {
  spreadsheetId: Scalars['String'],
  emails?: Maybe<Array<Maybe<Scalars['String']>>>,
  domain?: Maybe<Scalars['String']>,
};

export type ISpreadsheet = {
  spreadsheetId: Scalars['ID'],
  locationId: Scalars['ID'],
  url?: Maybe<Scalars['String']>,
  createTime?: Maybe<Scalars['String']>,
  updateTime?: Maybe<Scalars['String']>,
};

export type ITraveler = {
  travelerId?: Maybe<Scalars['ID']>,
  firstName?: Maybe<Scalars['String']>,
  lastName?: Maybe<Scalars['String']>,
  birthday?: Maybe<Scalars['String']>,
  gender?: Maybe<IGender>,
  email?: Maybe<Scalars['String']>,
  phone?: Maybe<Scalars['String']>,
  createTime?: Maybe<Scalars['String']>,
  updateTime?: Maybe<Scalars['String']>,
  passport?: Maybe<IPassport>,
  address?: Maybe<IAddress>,
};

export type ITravelerInput = {
  firstName: Scalars['String'],
  lastName: Scalars['String'],
  birthday: Scalars['String'],
  gender: IGender,
  email: Scalars['String'],
  phone: Scalars['String'],
  passport?: Maybe<IPassportInput>,
  address: IAddressInput,
};

export type ITrip = {
  seatsTaken?: Maybe<Scalars['Int']>,
  bookings?: Maybe<Array<Maybe<IBooking>>>,
  location?: Maybe<ILocation>,
  skus?: Maybe<Array<Maybe<ITripSku>>>,
  productId?: Maybe<Scalars['ID']>,
  tripId: Scalars['ID'],
  locationId: Scalars['ID'],
  startDate?: Maybe<Scalars['String']>,
  endDate?: Maybe<Scalars['String']>,
  maxSeats?: Maybe<Scalars['Int']>,
  detailsUrl?: Maybe<Scalars['String']>,
  active?: Maybe<Scalars['Boolean']>,
  archived?: Maybe<Scalars['Boolean']>,
  tags?: Maybe<Array<Maybe<Scalars['String']>>>,
  createTime?: Maybe<Scalars['String']>,
  updateTime?: Maybe<Scalars['String']>,
};

export type ITripsFilter = {
  locationId?: Maybe<Scalars['ID']>,
  archived?: Maybe<Scalars['Boolean']>,
  disabled?: Maybe<Scalars['Boolean']>,
};

export type ITripSku = {
  tripId?: Maybe<Scalars['ID']>,
  sku?: Maybe<Scalars['ID']>,
  type?: Maybe<Scalars['String']>,
  price?: Maybe<Scalars['Int']>,
  active?: Maybe<Scalars['Boolean']>,
  livemode?: Maybe<Scalars['Boolean']>,
  currency?: Maybe<Scalars['String']>,
  createTime?: Maybe<Scalars['String']>,
  updateTime?: Maybe<Scalars['String']>,
};

export type IUpdateBookingInput = {
  bookingId: Scalars['ID'],
  tripId: Scalars['ID'],
  returnCustomer: Scalars['Boolean'],
  roomArrangement: IRoomArrangement,
  status: IBookingStatus,
  friendName?: Maybe<Scalars['String']>,
};

export type IUpdateCouponInput = {
  couponId: Scalars['ID'],
  description?: Maybe<Scalars['String']>,
  startTime?: Maybe<Scalars['String']>,
  endTime?: Maybe<Scalars['String']>,
  maxRedemptions?: Maybe<Scalars['Int']>,
  global?: Maybe<Scalars['Boolean']>,
  disabled?: Maybe<Scalars['Boolean']>,
  rules?: Maybe<Array<Maybe<ICouponRuleInput>>>,
  locationIds?: Maybe<Array<Maybe<Scalars['String']>>>,
  tripIds?: Maybe<Array<Maybe<Scalars['String']>>>,
};

export type IUpdateLocationInput = {
  locationId: Scalars['ID'],
  title: Scalars['String'],
  country: Scalars['String'],
  description?: Maybe<Scalars['String']>,
  image?: Maybe<Scalars['String']>,
  included?: Maybe<Array<Maybe<Scalars['String']>>>,
  notIncluded?: Maybe<Array<Maybe<Scalars['String']>>>,
};

export type IUpdatePolicyInput = {
  policy: Scalars['String'],
};

export type IUpdateTravelerInput = {
  travelerId: Scalars['ID'],
  firstName: Scalars['String'],
  lastName: Scalars['String'],
  birthday: Scalars['String'],
  gender: IGender,
  email: Scalars['String'],
  phone: Scalars['String'],
  passport?: Maybe<IPassportInput>,
  address: IAddressInput,
};

export type IUpdateTripInput = {
  tripId: Scalars['ID'],
  startDate: Scalars['String'],
  endDate: Scalars['String'],
  maxSeats: Scalars['Int'],
  detailsUrl?: Maybe<Scalars['String']>,
  active: Scalars['Boolean'],
  archived?: Maybe<Scalars['Boolean']>,
  tags?: Maybe<Array<Maybe<Scalars['String']>>>,
};

export type IUpdateTripSkuInput = {
  tripId: Scalars['ID'],
  sku: Scalars['ID'],
  price: Scalars['Int'],
};


export type IGetBookingQueryVariables = {
  bookingId: Scalars['ID']
};


export type IGetBookingQuery = { booking: Maybe<(
    Pick<IBooking, 'bookingId' | 'tripId' | 'status' | 'returnCustomer' | 'roomArrangement' | 'friendName' | 'createTime' | 'updateTime'>
    & { travelers: Maybe<Array<(
      Pick<ITraveler, 'travelerId' | 'firstName' | 'lastName' | 'birthday' | 'gender' | 'email' | 'phone'>
      & { address: Maybe<Pick<IAddress, 'line1' | 'line2' | 'city' | 'region' | 'postalCode' | 'country'>>, passport: Maybe<Pick<IPassport, 'number' | 'expiration' | 'country'>> }
    )>>, trip: Maybe<(
      Pick<ITrip, 'tripId' | 'locationId' | 'startDate' | 'endDate' | 'detailsUrl'>
      & { location: Maybe<Pick<ILocation, 'locationId' | 'title' | 'country'>> }
    )>, order: Maybe<(
      Pick<IOrder, 'orderId' | 'bookingId' | 'amount' | 'couponIds'>
      & { priceAdjustments: Maybe<Array<Maybe<Pick<IOrderPriceAdjustment, 'id' | 'amount' | 'note' | 'createTime'>>>> }
    )>, changelogs: Maybe<Array<Pick<IBookingChangelog, 'eventId' | 'action' | 'rowData' | 'changedFields' | 'createTime'>>> }
  )> };

export type IUpdateBookingMutationVariables = {
  input: IUpdateBookingInput
};


export type IUpdateBookingMutation = { updateBooking: Maybe<Pick<IBooking, 'bookingId'>> };

export type IUpdateTravelerMutationVariables = {
  input: IUpdateTravelerInput
};


export type IUpdateTravelerMutation = { updateTraveler: Maybe<Pick<ITraveler, 'travelerId'>> };

export type IListBookingsQueryVariables = {
  pageToken?: Maybe<Scalars['String']>,
  pageSize?: Maybe<Scalars['Int']>,
  query?: Maybe<Scalars['String']>
};


export type IListBookingsQuery = { bookingPage: Maybe<(
    Pick<IBookingPage, 'nextPageToken'>
    & { bookings: Maybe<Array<(
      Pick<IBooking, 'bookingId' | 'tripId' | 'status' | 'returnCustomer' | 'roomArrangement' | 'friendName' | 'createTime' | 'updateTime'>
      & { travelers: Maybe<Array<Pick<ITraveler, 'firstName' | 'lastName'>>>, trip: Maybe<(
        Pick<ITrip, 'startDate'>
        & { location: Maybe<Pick<ILocation, 'title' | 'country'>> }
      )> }
    )>> }
  )> };

export type IListLocationForChangeTripQueryVariables = {};


export type IListLocationForChangeTripQuery = { locations: Maybe<Array<Maybe<Pick<ILocation, 'locationId' | 'title'>>>> };

export type IListTripsForChangeTripQueryVariables = {
  filter?: Maybe<ITripsFilter>
};


export type IListTripsForChangeTripQuery = { trips: Maybe<Array<Maybe<Pick<ITrip, 'tripId' | 'startDate' | 'endDate' | 'maxSeats' | 'seatsTaken'>>>> };

export type IUpdateBookingForChangeTripMutationVariables = {
  input: IUpdateBookingInput
};


export type IUpdateBookingForChangeTripMutation = { updateBooking: Maybe<Pick<IBooking, 'bookingId'>> };

export type IUpdateBookingStatusMutationVariables = {
  input: IUpdateBookingInput
};


export type IUpdateBookingStatusMutation = { updateBooking: Maybe<Pick<IBooking, 'bookingId' | 'status' | 'updateTime'>> };

export type ICreateOrderPriceAdjustmentMutationVariables = {
  input: ICreateOrderPriceAdjustmentInput
};


export type ICreateOrderPriceAdjustmentMutation = { createOrderPriceAdjustment: Maybe<Pick<IOrderPriceAdjustment, 'id' | 'amount' | 'note' | 'createTime'>> };

export type IListLocationForSelectTripQueryVariables = {};


export type IListLocationForSelectTripQuery = { locations: Maybe<Array<Maybe<Pick<ILocation, 'locationId' | 'title' | 'country'>>>> };

export type ICreateCouponMutationVariables = {
  input: ICreateCouponInput
};


export type ICreateCouponMutation = { createCoupon: Maybe<Pick<ICoupon, 'couponId'>> };

export type IGetCouponQueryVariables = {
  couponId: Scalars['ID']
};


export type IGetCouponQuery = { coupon: Maybe<(
    Pick<ICoupon, 'couponId' | 'description' | 'startTime' | 'endTime' | 'maxRedemptions' | 'global' | 'disabled' | 'deletedTime' | 'locationIds' | 'tripIds'>
    & { locations: Maybe<Array<Maybe<Pick<ILocation, 'locationId' | 'title'>>>>, trips: Maybe<Array<Maybe<(
      Pick<ITrip, 'tripId' | 'startDate'>
      & { location: Maybe<Pick<ILocation, 'title'>> }
    )>>>, rules: Maybe<Array<Maybe<(
      Pick<ICouponRule, 'type' | 'value'>
      & { conditions: Maybe<Array<Maybe<Pick<ICouponRuleCondition, 'field' | 'operator' | 'value'>>>> }
    )>>> }
  )> };

export type IUpdateCouponMutationVariables = {
  input: IUpdateCouponInput
};


export type IUpdateCouponMutation = { updateCoupon: Maybe<(
    Pick<ICoupon, 'couponId' | 'description' | 'startTime' | 'endTime' | 'maxRedemptions' | 'global' | 'disabled' | 'deletedTime' | 'locationIds' | 'tripIds' | 'createTime' | 'updateTime'>
    & { locations: Maybe<Array<Maybe<Pick<ILocation, 'locationId' | 'title'>>>>, trips: Maybe<Array<Maybe<(
      Pick<ITrip, 'tripId' | 'startDate'>
      & { location: Maybe<Pick<ILocation, 'title'>> }
    )>>>, rules: Maybe<Array<Maybe<(
      Pick<ICouponRule, 'type' | 'value'>
      & { conditions: Maybe<Array<Maybe<Pick<ICouponRuleCondition, 'field' | 'operator' | 'value'>>>> }
    )>>> }
  )> };

export type IDeleteCouponMutationVariables = {
  couponId: Scalars['ID']
};


export type IDeleteCouponMutation = Pick<IMutation, 'deleteCoupon'>;

export type IListCouponsQueryVariables = {};


export type IListCouponsQuery = { coupons: Maybe<Array<Maybe<Pick<ICoupon, 'couponId' | 'description' | 'startTime' | 'endTime' | 'global' | 'disabled' | 'maxRedemptions' | 'createTime' | 'updateTime'>>>> };

export type IListLocationsCouponFormQueryVariables = {};


export type IListLocationsCouponFormQuery = { locations: Maybe<Array<Maybe<Pick<ILocation, 'locationId' | 'title'>>>> };

export type IListTripsCouponFormQueryVariables = {
  filter?: Maybe<ITripsFilter>
};


export type IListTripsCouponFormQuery = { trips: Maybe<Array<Maybe<(
    Pick<ITrip, 'tripId' | 'startDate'>
    & { location: Maybe<Pick<ILocation, 'title'>> }
  )>>> };

export type ICreateLocationMutationVariables = {
  input: ICreateLocationInput
};


export type ICreateLocationMutation = { createLocation: Maybe<Pick<ILocation, 'locationId'>> };

export type IGetLocationQueryVariables = {
  locationId: Scalars['ID']
};


export type IGetLocationQuery = { location: Maybe<Pick<ILocation, 'locationId' | 'country' | 'title' | 'description' | 'included' | 'notIncluded' | 'image'>> };

export type IUpdateLocationMutationVariables = {
  input?: Maybe<IUpdateLocationInput>
};


export type IUpdateLocationMutation = { updateLocation: Maybe<Pick<ILocation, 'locationId'>> };

export type IListLocationsQueryVariables = {};


export type IListLocationsQuery = { locations: Maybe<Array<Maybe<(
    Pick<ILocation, 'locationId' | 'title' | 'description' | 'country'>
    & { cntry: Maybe<Pick<ICountry, 'name' | 'region' | 'subRegion' | 'intermediateRegion'>> }
  )>>> };

export type IGetPolicyQueryVariables = {};


export type IGetPolicyQuery = Pick<IQuery, 'policy'>;

export type IUpdatePolicyMutationVariables = {
  input: IUpdatePolicyInput
};


export type IUpdatePolicyMutation = Pick<IMutation, 'updatePolicy'>;

export type IListLocationsForDropdownQueryVariables = {};


export type IListLocationsForDropdownQuery = { locations: Maybe<Array<Maybe<Pick<ILocation, 'locationId' | 'country' | 'title'>>>> };

export type ICreateTripMutationVariables = {
  input: ICreateTripInput
};


export type ICreateTripMutation = { createTrip: Maybe<Pick<ITrip, 'tripId'>> };

export type IGetTripQueryVariables = {
  tripId: Scalars['ID']
};


export type IGetTripQuery = { trip: Maybe<(
    Pick<ITrip, 'tripId' | 'locationId' | 'startDate' | 'endDate' | 'maxSeats' | 'detailsUrl' | 'active' | 'archived' | 'tags'>
    & { skus: Maybe<Array<Maybe<Pick<ITripSku, 'sku' | 'type' | 'price'>>>>, location: Maybe<Pick<ILocation, 'locationId' | 'country' | 'title'>> }
  )> };

export type IUpdateTripMutationVariables = {
  input: IUpdateTripInput
};


export type IUpdateTripMutation = { updateTrip: Maybe<Pick<ITrip, 'tripId' | 'locationId' | 'startDate' | 'endDate' | 'maxSeats' | 'detailsUrl' | 'active' | 'tags'>> };

export type IUpdateTripSkuMutationVariables = {
  input: IUpdateTripSkuInput
};


export type IUpdateTripSkuMutation = { updateTripSKU: Maybe<Pick<ITripSku, 'sku'>> };

export type IListTripsQueryVariables = {
  filter?: Maybe<ITripsFilter>
};


export type IListTripsQuery = { trips: Maybe<Array<Maybe<(
    Pick<ITrip, 'tripId' | 'startDate' | 'endDate' | 'locationId' | 'archived' | 'active' | 'maxSeats' | 'seatsTaken' | 'tags'>
    & { location: Maybe<(
      Pick<ILocation, 'locationId' | 'country' | 'title'>
      & { cntry: Maybe<Pick<ICountry, 'name' | 'region' | 'subRegion' | 'intermediateRegion'>> }
    )> }
  )>>>, locations: Maybe<Array<Maybe<Pick<ILocation, 'locationId' | 'country' | 'title'>>>> };


export const GetBookingDocument = gql`
    query GetBooking($bookingId: ID!) {
  booking(bookingId: $bookingId) {
    bookingId
    tripId
    status
    returnCustomer
    roomArrangement
    friendName
    createTime
    updateTime
    travelers {
      travelerId
      firstName
      lastName
      birthday
      gender
      email
      phone
      address {
        line1
        line2
        city
        region
        postalCode
        country
      }
      passport {
        number
        expiration
        country
      }
    }
    trip {
      tripId
      locationId
      startDate
      endDate
      detailsUrl
      location {
        locationId
        title
        country
      }
    }
    order {
      orderId
      bookingId
      amount
      couponIds
      priceAdjustments {
        id
        amount
        note
        createTime
      }
    }
    changelogs {
      eventId
      action
      rowData
      changedFields
      createTime
    }
  }
}
    `;

/**
 * __useGetBookingQuery__
 *
 * To run a query within a React component, call `useGetBookingQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetBookingQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetBookingQuery({
 *   variables: {
 *      bookingId: // value for 'bookingId'
 *   },
 * });
 */
export function useGetBookingQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<IGetBookingQuery, IGetBookingQueryVariables>) {
        return ApolloReactHooks.useQuery<IGetBookingQuery, IGetBookingQueryVariables>(GetBookingDocument, baseOptions);
      }
export function useGetBookingLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<IGetBookingQuery, IGetBookingQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<IGetBookingQuery, IGetBookingQueryVariables>(GetBookingDocument, baseOptions);
        }
export type GetBookingQueryHookResult = ReturnType<typeof useGetBookingQuery>;
export type GetBookingLazyQueryHookResult = ReturnType<typeof useGetBookingLazyQuery>;
export type GetBookingQueryResult = ApolloReactCommon.QueryResult<IGetBookingQuery, IGetBookingQueryVariables>;
export const UpdateBookingDocument = gql`
    mutation UpdateBooking($input: UpdateBookingInput!) {
  updateBooking(input: $input) {
    bookingId
  }
}
    `;
export type IUpdateBookingMutationFn = ApolloReactCommon.MutationFunction<IUpdateBookingMutation, IUpdateBookingMutationVariables>;

/**
 * __useUpdateBookingMutation__
 *
 * To run a mutation, you first call `useUpdateBookingMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateBookingMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateBookingMutation, { data, loading, error }] = useUpdateBookingMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateBookingMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<IUpdateBookingMutation, IUpdateBookingMutationVariables>) {
        return ApolloReactHooks.useMutation<IUpdateBookingMutation, IUpdateBookingMutationVariables>(UpdateBookingDocument, baseOptions);
      }
export type UpdateBookingMutationHookResult = ReturnType<typeof useUpdateBookingMutation>;
export type UpdateBookingMutationResult = ApolloReactCommon.MutationResult<IUpdateBookingMutation>;
export type UpdateBookingMutationOptions = ApolloReactCommon.BaseMutationOptions<IUpdateBookingMutation, IUpdateBookingMutationVariables>;
export const UpdateTravelerDocument = gql`
    mutation UpdateTraveler($input: UpdateTravelerInput!) {
  updateTraveler(input: $input) {
    travelerId
  }
}
    `;
export type IUpdateTravelerMutationFn = ApolloReactCommon.MutationFunction<IUpdateTravelerMutation, IUpdateTravelerMutationVariables>;

/**
 * __useUpdateTravelerMutation__
 *
 * To run a mutation, you first call `useUpdateTravelerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateTravelerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateTravelerMutation, { data, loading, error }] = useUpdateTravelerMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateTravelerMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<IUpdateTravelerMutation, IUpdateTravelerMutationVariables>) {
        return ApolloReactHooks.useMutation<IUpdateTravelerMutation, IUpdateTravelerMutationVariables>(UpdateTravelerDocument, baseOptions);
      }
export type UpdateTravelerMutationHookResult = ReturnType<typeof useUpdateTravelerMutation>;
export type UpdateTravelerMutationResult = ApolloReactCommon.MutationResult<IUpdateTravelerMutation>;
export type UpdateTravelerMutationOptions = ApolloReactCommon.BaseMutationOptions<IUpdateTravelerMutation, IUpdateTravelerMutationVariables>;
export const ListBookingsDocument = gql`
    query ListBookings($pageToken: String, $pageSize: Int, $query: String) {
  bookingPage(pageToken: $pageToken, pageSize: $pageSize, query: $query) {
    nextPageToken
    bookings {
      bookingId
      tripId
      status
      returnCustomer
      roomArrangement
      friendName
      createTime
      updateTime
      travelers {
        firstName
        lastName
      }
      trip {
        startDate
        location {
          title
          country
        }
      }
    }
  }
}
    `;

/**
 * __useListBookingsQuery__
 *
 * To run a query within a React component, call `useListBookingsQuery` and pass it any options that fit your needs.
 * When your component renders, `useListBookingsQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListBookingsQuery({
 *   variables: {
 *      pageToken: // value for 'pageToken'
 *      pageSize: // value for 'pageSize'
 *      query: // value for 'query'
 *   },
 * });
 */
export function useListBookingsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<IListBookingsQuery, IListBookingsQueryVariables>) {
        return ApolloReactHooks.useQuery<IListBookingsQuery, IListBookingsQueryVariables>(ListBookingsDocument, baseOptions);
      }
export function useListBookingsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<IListBookingsQuery, IListBookingsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<IListBookingsQuery, IListBookingsQueryVariables>(ListBookingsDocument, baseOptions);
        }
export type ListBookingsQueryHookResult = ReturnType<typeof useListBookingsQuery>;
export type ListBookingsLazyQueryHookResult = ReturnType<typeof useListBookingsLazyQuery>;
export type ListBookingsQueryResult = ApolloReactCommon.QueryResult<IListBookingsQuery, IListBookingsQueryVariables>;
export const ListLocationForChangeTripDocument = gql`
    query ListLocationForChangeTrip {
  locations {
    locationId
    title
  }
}
    `;

/**
 * __useListLocationForChangeTripQuery__
 *
 * To run a query within a React component, call `useListLocationForChangeTripQuery` and pass it any options that fit your needs.
 * When your component renders, `useListLocationForChangeTripQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListLocationForChangeTripQuery({
 *   variables: {
 *   },
 * });
 */
export function useListLocationForChangeTripQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<IListLocationForChangeTripQuery, IListLocationForChangeTripQueryVariables>) {
        return ApolloReactHooks.useQuery<IListLocationForChangeTripQuery, IListLocationForChangeTripQueryVariables>(ListLocationForChangeTripDocument, baseOptions);
      }
export function useListLocationForChangeTripLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<IListLocationForChangeTripQuery, IListLocationForChangeTripQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<IListLocationForChangeTripQuery, IListLocationForChangeTripQueryVariables>(ListLocationForChangeTripDocument, baseOptions);
        }
export type ListLocationForChangeTripQueryHookResult = ReturnType<typeof useListLocationForChangeTripQuery>;
export type ListLocationForChangeTripLazyQueryHookResult = ReturnType<typeof useListLocationForChangeTripLazyQuery>;
export type ListLocationForChangeTripQueryResult = ApolloReactCommon.QueryResult<IListLocationForChangeTripQuery, IListLocationForChangeTripQueryVariables>;
export const ListTripsForChangeTripDocument = gql`
    query ListTripsForChangeTrip($filter: TripsFilter) {
  trips(filter: $filter) {
    tripId
    startDate
    endDate
    maxSeats
    seatsTaken
  }
}
    `;

/**
 * __useListTripsForChangeTripQuery__
 *
 * To run a query within a React component, call `useListTripsForChangeTripQuery` and pass it any options that fit your needs.
 * When your component renders, `useListTripsForChangeTripQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListTripsForChangeTripQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useListTripsForChangeTripQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<IListTripsForChangeTripQuery, IListTripsForChangeTripQueryVariables>) {
        return ApolloReactHooks.useQuery<IListTripsForChangeTripQuery, IListTripsForChangeTripQueryVariables>(ListTripsForChangeTripDocument, baseOptions);
      }
export function useListTripsForChangeTripLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<IListTripsForChangeTripQuery, IListTripsForChangeTripQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<IListTripsForChangeTripQuery, IListTripsForChangeTripQueryVariables>(ListTripsForChangeTripDocument, baseOptions);
        }
export type ListTripsForChangeTripQueryHookResult = ReturnType<typeof useListTripsForChangeTripQuery>;
export type ListTripsForChangeTripLazyQueryHookResult = ReturnType<typeof useListTripsForChangeTripLazyQuery>;
export type ListTripsForChangeTripQueryResult = ApolloReactCommon.QueryResult<IListTripsForChangeTripQuery, IListTripsForChangeTripQueryVariables>;
export const UpdateBookingForChangeTripDocument = gql`
    mutation UpdateBookingForChangeTrip($input: UpdateBookingInput!) {
  updateBooking(input: $input) {
    bookingId
  }
}
    `;
export type IUpdateBookingForChangeTripMutationFn = ApolloReactCommon.MutationFunction<IUpdateBookingForChangeTripMutation, IUpdateBookingForChangeTripMutationVariables>;

/**
 * __useUpdateBookingForChangeTripMutation__
 *
 * To run a mutation, you first call `useUpdateBookingForChangeTripMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateBookingForChangeTripMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateBookingForChangeTripMutation, { data, loading, error }] = useUpdateBookingForChangeTripMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateBookingForChangeTripMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<IUpdateBookingForChangeTripMutation, IUpdateBookingForChangeTripMutationVariables>) {
        return ApolloReactHooks.useMutation<IUpdateBookingForChangeTripMutation, IUpdateBookingForChangeTripMutationVariables>(UpdateBookingForChangeTripDocument, baseOptions);
      }
export type UpdateBookingForChangeTripMutationHookResult = ReturnType<typeof useUpdateBookingForChangeTripMutation>;
export type UpdateBookingForChangeTripMutationResult = ApolloReactCommon.MutationResult<IUpdateBookingForChangeTripMutation>;
export type UpdateBookingForChangeTripMutationOptions = ApolloReactCommon.BaseMutationOptions<IUpdateBookingForChangeTripMutation, IUpdateBookingForChangeTripMutationVariables>;
export const UpdateBookingStatusDocument = gql`
    mutation UpdateBookingStatus($input: UpdateBookingInput!) {
  updateBooking(input: $input) {
    bookingId
    status
    updateTime
  }
}
    `;
export type IUpdateBookingStatusMutationFn = ApolloReactCommon.MutationFunction<IUpdateBookingStatusMutation, IUpdateBookingStatusMutationVariables>;

/**
 * __useUpdateBookingStatusMutation__
 *
 * To run a mutation, you first call `useUpdateBookingStatusMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateBookingStatusMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateBookingStatusMutation, { data, loading, error }] = useUpdateBookingStatusMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateBookingStatusMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<IUpdateBookingStatusMutation, IUpdateBookingStatusMutationVariables>) {
        return ApolloReactHooks.useMutation<IUpdateBookingStatusMutation, IUpdateBookingStatusMutationVariables>(UpdateBookingStatusDocument, baseOptions);
      }
export type UpdateBookingStatusMutationHookResult = ReturnType<typeof useUpdateBookingStatusMutation>;
export type UpdateBookingStatusMutationResult = ApolloReactCommon.MutationResult<IUpdateBookingStatusMutation>;
export type UpdateBookingStatusMutationOptions = ApolloReactCommon.BaseMutationOptions<IUpdateBookingStatusMutation, IUpdateBookingStatusMutationVariables>;
export const CreateOrderPriceAdjustmentDocument = gql`
    mutation CreateOrderPriceAdjustment($input: CreateOrderPriceAdjustmentInput!) {
  createOrderPriceAdjustment(input: $input) {
    id
    amount
    note
    createTime
  }
}
    `;
export type ICreateOrderPriceAdjustmentMutationFn = ApolloReactCommon.MutationFunction<ICreateOrderPriceAdjustmentMutation, ICreateOrderPriceAdjustmentMutationVariables>;

/**
 * __useCreateOrderPriceAdjustmentMutation__
 *
 * To run a mutation, you first call `useCreateOrderPriceAdjustmentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateOrderPriceAdjustmentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createOrderPriceAdjustmentMutation, { data, loading, error }] = useCreateOrderPriceAdjustmentMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateOrderPriceAdjustmentMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<ICreateOrderPriceAdjustmentMutation, ICreateOrderPriceAdjustmentMutationVariables>) {
        return ApolloReactHooks.useMutation<ICreateOrderPriceAdjustmentMutation, ICreateOrderPriceAdjustmentMutationVariables>(CreateOrderPriceAdjustmentDocument, baseOptions);
      }
export type CreateOrderPriceAdjustmentMutationHookResult = ReturnType<typeof useCreateOrderPriceAdjustmentMutation>;
export type CreateOrderPriceAdjustmentMutationResult = ApolloReactCommon.MutationResult<ICreateOrderPriceAdjustmentMutation>;
export type CreateOrderPriceAdjustmentMutationOptions = ApolloReactCommon.BaseMutationOptions<ICreateOrderPriceAdjustmentMutation, ICreateOrderPriceAdjustmentMutationVariables>;
export const ListLocationForSelectTripDocument = gql`
    query ListLocationForSelectTrip {
  locations {
    locationId
    title
    country
  }
}
    `;

/**
 * __useListLocationForSelectTripQuery__
 *
 * To run a query within a React component, call `useListLocationForSelectTripQuery` and pass it any options that fit your needs.
 * When your component renders, `useListLocationForSelectTripQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListLocationForSelectTripQuery({
 *   variables: {
 *   },
 * });
 */
export function useListLocationForSelectTripQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<IListLocationForSelectTripQuery, IListLocationForSelectTripQueryVariables>) {
        return ApolloReactHooks.useQuery<IListLocationForSelectTripQuery, IListLocationForSelectTripQueryVariables>(ListLocationForSelectTripDocument, baseOptions);
      }
export function useListLocationForSelectTripLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<IListLocationForSelectTripQuery, IListLocationForSelectTripQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<IListLocationForSelectTripQuery, IListLocationForSelectTripQueryVariables>(ListLocationForSelectTripDocument, baseOptions);
        }
export type ListLocationForSelectTripQueryHookResult = ReturnType<typeof useListLocationForSelectTripQuery>;
export type ListLocationForSelectTripLazyQueryHookResult = ReturnType<typeof useListLocationForSelectTripLazyQuery>;
export type ListLocationForSelectTripQueryResult = ApolloReactCommon.QueryResult<IListLocationForSelectTripQuery, IListLocationForSelectTripQueryVariables>;
export const CreateCouponDocument = gql`
    mutation CreateCoupon($input: CreateCouponInput!) {
  createCoupon(input: $input) {
    couponId
  }
}
    `;
export type ICreateCouponMutationFn = ApolloReactCommon.MutationFunction<ICreateCouponMutation, ICreateCouponMutationVariables>;

/**
 * __useCreateCouponMutation__
 *
 * To run a mutation, you first call `useCreateCouponMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateCouponMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createCouponMutation, { data, loading, error }] = useCreateCouponMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateCouponMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<ICreateCouponMutation, ICreateCouponMutationVariables>) {
        return ApolloReactHooks.useMutation<ICreateCouponMutation, ICreateCouponMutationVariables>(CreateCouponDocument, baseOptions);
      }
export type CreateCouponMutationHookResult = ReturnType<typeof useCreateCouponMutation>;
export type CreateCouponMutationResult = ApolloReactCommon.MutationResult<ICreateCouponMutation>;
export type CreateCouponMutationOptions = ApolloReactCommon.BaseMutationOptions<ICreateCouponMutation, ICreateCouponMutationVariables>;
export const GetCouponDocument = gql`
    query GetCoupon($couponId: ID!) {
  coupon(couponId: $couponId) {
    couponId
    description
    startTime
    endTime
    maxRedemptions
    global
    disabled
    deletedTime
    locationIds
    locations {
      locationId
      title
    }
    tripIds
    trips {
      tripId
      location {
        title
      }
      startDate
    }
    rules {
      type
      value
      conditions {
        field
        operator
        value
      }
    }
  }
}
    `;

/**
 * __useGetCouponQuery__
 *
 * To run a query within a React component, call `useGetCouponQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCouponQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCouponQuery({
 *   variables: {
 *      couponId: // value for 'couponId'
 *   },
 * });
 */
export function useGetCouponQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<IGetCouponQuery, IGetCouponQueryVariables>) {
        return ApolloReactHooks.useQuery<IGetCouponQuery, IGetCouponQueryVariables>(GetCouponDocument, baseOptions);
      }
export function useGetCouponLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<IGetCouponQuery, IGetCouponQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<IGetCouponQuery, IGetCouponQueryVariables>(GetCouponDocument, baseOptions);
        }
export type GetCouponQueryHookResult = ReturnType<typeof useGetCouponQuery>;
export type GetCouponLazyQueryHookResult = ReturnType<typeof useGetCouponLazyQuery>;
export type GetCouponQueryResult = ApolloReactCommon.QueryResult<IGetCouponQuery, IGetCouponQueryVariables>;
export const UpdateCouponDocument = gql`
    mutation UpdateCoupon($input: UpdateCouponInput!) {
  updateCoupon(input: $input) {
    couponId
    description
    startTime
    endTime
    maxRedemptions
    global
    disabled
    deletedTime
    locationIds
    locations {
      locationId
      title
    }
    tripIds
    trips {
      tripId
      location {
        title
      }
      startDate
    }
    rules {
      type
      value
      conditions {
        field
        operator
        value
      }
    }
    createTime
    updateTime
  }
}
    `;
export type IUpdateCouponMutationFn = ApolloReactCommon.MutationFunction<IUpdateCouponMutation, IUpdateCouponMutationVariables>;

/**
 * __useUpdateCouponMutation__
 *
 * To run a mutation, you first call `useUpdateCouponMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCouponMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCouponMutation, { data, loading, error }] = useUpdateCouponMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateCouponMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<IUpdateCouponMutation, IUpdateCouponMutationVariables>) {
        return ApolloReactHooks.useMutation<IUpdateCouponMutation, IUpdateCouponMutationVariables>(UpdateCouponDocument, baseOptions);
      }
export type UpdateCouponMutationHookResult = ReturnType<typeof useUpdateCouponMutation>;
export type UpdateCouponMutationResult = ApolloReactCommon.MutationResult<IUpdateCouponMutation>;
export type UpdateCouponMutationOptions = ApolloReactCommon.BaseMutationOptions<IUpdateCouponMutation, IUpdateCouponMutationVariables>;
export const DeleteCouponDocument = gql`
    mutation DeleteCoupon($couponId: ID!) {
  deleteCoupon(couponId: $couponId)
}
    `;
export type IDeleteCouponMutationFn = ApolloReactCommon.MutationFunction<IDeleteCouponMutation, IDeleteCouponMutationVariables>;

/**
 * __useDeleteCouponMutation__
 *
 * To run a mutation, you first call `useDeleteCouponMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteCouponMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteCouponMutation, { data, loading, error }] = useDeleteCouponMutation({
 *   variables: {
 *      couponId: // value for 'couponId'
 *   },
 * });
 */
export function useDeleteCouponMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<IDeleteCouponMutation, IDeleteCouponMutationVariables>) {
        return ApolloReactHooks.useMutation<IDeleteCouponMutation, IDeleteCouponMutationVariables>(DeleteCouponDocument, baseOptions);
      }
export type DeleteCouponMutationHookResult = ReturnType<typeof useDeleteCouponMutation>;
export type DeleteCouponMutationResult = ApolloReactCommon.MutationResult<IDeleteCouponMutation>;
export type DeleteCouponMutationOptions = ApolloReactCommon.BaseMutationOptions<IDeleteCouponMutation, IDeleteCouponMutationVariables>;
export const ListCouponsDocument = gql`
    query ListCoupons {
  coupons {
    couponId
    description
    startTime
    endTime
    global
    disabled
    maxRedemptions
    createTime
    updateTime
  }
}
    `;

/**
 * __useListCouponsQuery__
 *
 * To run a query within a React component, call `useListCouponsQuery` and pass it any options that fit your needs.
 * When your component renders, `useListCouponsQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListCouponsQuery({
 *   variables: {
 *   },
 * });
 */
export function useListCouponsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<IListCouponsQuery, IListCouponsQueryVariables>) {
        return ApolloReactHooks.useQuery<IListCouponsQuery, IListCouponsQueryVariables>(ListCouponsDocument, baseOptions);
      }
export function useListCouponsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<IListCouponsQuery, IListCouponsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<IListCouponsQuery, IListCouponsQueryVariables>(ListCouponsDocument, baseOptions);
        }
export type ListCouponsQueryHookResult = ReturnType<typeof useListCouponsQuery>;
export type ListCouponsLazyQueryHookResult = ReturnType<typeof useListCouponsLazyQuery>;
export type ListCouponsQueryResult = ApolloReactCommon.QueryResult<IListCouponsQuery, IListCouponsQueryVariables>;
export const ListLocationsCouponFormDocument = gql`
    query ListLocationsCouponForm {
  locations {
    locationId
    title
  }
}
    `;

/**
 * __useListLocationsCouponFormQuery__
 *
 * To run a query within a React component, call `useListLocationsCouponFormQuery` and pass it any options that fit your needs.
 * When your component renders, `useListLocationsCouponFormQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListLocationsCouponFormQuery({
 *   variables: {
 *   },
 * });
 */
export function useListLocationsCouponFormQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<IListLocationsCouponFormQuery, IListLocationsCouponFormQueryVariables>) {
        return ApolloReactHooks.useQuery<IListLocationsCouponFormQuery, IListLocationsCouponFormQueryVariables>(ListLocationsCouponFormDocument, baseOptions);
      }
export function useListLocationsCouponFormLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<IListLocationsCouponFormQuery, IListLocationsCouponFormQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<IListLocationsCouponFormQuery, IListLocationsCouponFormQueryVariables>(ListLocationsCouponFormDocument, baseOptions);
        }
export type ListLocationsCouponFormQueryHookResult = ReturnType<typeof useListLocationsCouponFormQuery>;
export type ListLocationsCouponFormLazyQueryHookResult = ReturnType<typeof useListLocationsCouponFormLazyQuery>;
export type ListLocationsCouponFormQueryResult = ApolloReactCommon.QueryResult<IListLocationsCouponFormQuery, IListLocationsCouponFormQueryVariables>;
export const ListTripsCouponFormDocument = gql`
    query ListTripsCouponForm($filter: TripsFilter) {
  trips(filter: $filter) {
    tripId
    startDate
    location {
      title
    }
  }
}
    `;

/**
 * __useListTripsCouponFormQuery__
 *
 * To run a query within a React component, call `useListTripsCouponFormQuery` and pass it any options that fit your needs.
 * When your component renders, `useListTripsCouponFormQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListTripsCouponFormQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useListTripsCouponFormQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<IListTripsCouponFormQuery, IListTripsCouponFormQueryVariables>) {
        return ApolloReactHooks.useQuery<IListTripsCouponFormQuery, IListTripsCouponFormQueryVariables>(ListTripsCouponFormDocument, baseOptions);
      }
export function useListTripsCouponFormLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<IListTripsCouponFormQuery, IListTripsCouponFormQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<IListTripsCouponFormQuery, IListTripsCouponFormQueryVariables>(ListTripsCouponFormDocument, baseOptions);
        }
export type ListTripsCouponFormQueryHookResult = ReturnType<typeof useListTripsCouponFormQuery>;
export type ListTripsCouponFormLazyQueryHookResult = ReturnType<typeof useListTripsCouponFormLazyQuery>;
export type ListTripsCouponFormQueryResult = ApolloReactCommon.QueryResult<IListTripsCouponFormQuery, IListTripsCouponFormQueryVariables>;
export const CreateLocationDocument = gql`
    mutation CreateLocation($input: CreateLocationInput!) {
  createLocation(input: $input) {
    locationId
  }
}
    `;
export type ICreateLocationMutationFn = ApolloReactCommon.MutationFunction<ICreateLocationMutation, ICreateLocationMutationVariables>;

/**
 * __useCreateLocationMutation__
 *
 * To run a mutation, you first call `useCreateLocationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateLocationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createLocationMutation, { data, loading, error }] = useCreateLocationMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateLocationMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<ICreateLocationMutation, ICreateLocationMutationVariables>) {
        return ApolloReactHooks.useMutation<ICreateLocationMutation, ICreateLocationMutationVariables>(CreateLocationDocument, baseOptions);
      }
export type CreateLocationMutationHookResult = ReturnType<typeof useCreateLocationMutation>;
export type CreateLocationMutationResult = ApolloReactCommon.MutationResult<ICreateLocationMutation>;
export type CreateLocationMutationOptions = ApolloReactCommon.BaseMutationOptions<ICreateLocationMutation, ICreateLocationMutationVariables>;
export const GetLocationDocument = gql`
    query GetLocation($locationId: ID!) {
  location(locationId: $locationId) {
    locationId
    country
    title
    description
    included
    notIncluded
    image
  }
}
    `;

/**
 * __useGetLocationQuery__
 *
 * To run a query within a React component, call `useGetLocationQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetLocationQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetLocationQuery({
 *   variables: {
 *      locationId: // value for 'locationId'
 *   },
 * });
 */
export function useGetLocationQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<IGetLocationQuery, IGetLocationQueryVariables>) {
        return ApolloReactHooks.useQuery<IGetLocationQuery, IGetLocationQueryVariables>(GetLocationDocument, baseOptions);
      }
export function useGetLocationLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<IGetLocationQuery, IGetLocationQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<IGetLocationQuery, IGetLocationQueryVariables>(GetLocationDocument, baseOptions);
        }
export type GetLocationQueryHookResult = ReturnType<typeof useGetLocationQuery>;
export type GetLocationLazyQueryHookResult = ReturnType<typeof useGetLocationLazyQuery>;
export type GetLocationQueryResult = ApolloReactCommon.QueryResult<IGetLocationQuery, IGetLocationQueryVariables>;
export const UpdateLocationDocument = gql`
    mutation UpdateLocation($input: UpdateLocationInput) {
  updateLocation(input: $input) {
    locationId
  }
}
    `;
export type IUpdateLocationMutationFn = ApolloReactCommon.MutationFunction<IUpdateLocationMutation, IUpdateLocationMutationVariables>;

/**
 * __useUpdateLocationMutation__
 *
 * To run a mutation, you first call `useUpdateLocationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateLocationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateLocationMutation, { data, loading, error }] = useUpdateLocationMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateLocationMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<IUpdateLocationMutation, IUpdateLocationMutationVariables>) {
        return ApolloReactHooks.useMutation<IUpdateLocationMutation, IUpdateLocationMutationVariables>(UpdateLocationDocument, baseOptions);
      }
export type UpdateLocationMutationHookResult = ReturnType<typeof useUpdateLocationMutation>;
export type UpdateLocationMutationResult = ApolloReactCommon.MutationResult<IUpdateLocationMutation>;
export type UpdateLocationMutationOptions = ApolloReactCommon.BaseMutationOptions<IUpdateLocationMutation, IUpdateLocationMutationVariables>;
export const ListLocationsDocument = gql`
    query ListLocations {
  locations {
    locationId
    title
    description
    country
    cntry {
      name
      region
      subRegion
      intermediateRegion
    }
  }
}
    `;

/**
 * __useListLocationsQuery__
 *
 * To run a query within a React component, call `useListLocationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useListLocationsQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListLocationsQuery({
 *   variables: {
 *   },
 * });
 */
export function useListLocationsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<IListLocationsQuery, IListLocationsQueryVariables>) {
        return ApolloReactHooks.useQuery<IListLocationsQuery, IListLocationsQueryVariables>(ListLocationsDocument, baseOptions);
      }
export function useListLocationsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<IListLocationsQuery, IListLocationsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<IListLocationsQuery, IListLocationsQueryVariables>(ListLocationsDocument, baseOptions);
        }
export type ListLocationsQueryHookResult = ReturnType<typeof useListLocationsQuery>;
export type ListLocationsLazyQueryHookResult = ReturnType<typeof useListLocationsLazyQuery>;
export type ListLocationsQueryResult = ApolloReactCommon.QueryResult<IListLocationsQuery, IListLocationsQueryVariables>;
export const GetPolicyDocument = gql`
    query GetPolicy {
  policy
}
    `;

/**
 * __useGetPolicyQuery__
 *
 * To run a query within a React component, call `useGetPolicyQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPolicyQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPolicyQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetPolicyQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<IGetPolicyQuery, IGetPolicyQueryVariables>) {
        return ApolloReactHooks.useQuery<IGetPolicyQuery, IGetPolicyQueryVariables>(GetPolicyDocument, baseOptions);
      }
export function useGetPolicyLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<IGetPolicyQuery, IGetPolicyQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<IGetPolicyQuery, IGetPolicyQueryVariables>(GetPolicyDocument, baseOptions);
        }
export type GetPolicyQueryHookResult = ReturnType<typeof useGetPolicyQuery>;
export type GetPolicyLazyQueryHookResult = ReturnType<typeof useGetPolicyLazyQuery>;
export type GetPolicyQueryResult = ApolloReactCommon.QueryResult<IGetPolicyQuery, IGetPolicyQueryVariables>;
export const UpdatePolicyDocument = gql`
    mutation UpdatePolicy($input: UpdatePolicyInput!) {
  updatePolicy(input: $input)
}
    `;
export type IUpdatePolicyMutationFn = ApolloReactCommon.MutationFunction<IUpdatePolicyMutation, IUpdatePolicyMutationVariables>;

/**
 * __useUpdatePolicyMutation__
 *
 * To run a mutation, you first call `useUpdatePolicyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePolicyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePolicyMutation, { data, loading, error }] = useUpdatePolicyMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdatePolicyMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<IUpdatePolicyMutation, IUpdatePolicyMutationVariables>) {
        return ApolloReactHooks.useMutation<IUpdatePolicyMutation, IUpdatePolicyMutationVariables>(UpdatePolicyDocument, baseOptions);
      }
export type UpdatePolicyMutationHookResult = ReturnType<typeof useUpdatePolicyMutation>;
export type UpdatePolicyMutationResult = ApolloReactCommon.MutationResult<IUpdatePolicyMutation>;
export type UpdatePolicyMutationOptions = ApolloReactCommon.BaseMutationOptions<IUpdatePolicyMutation, IUpdatePolicyMutationVariables>;
export const ListLocationsForDropdownDocument = gql`
    query ListLocationsForDropdown {
  locations {
    locationId
    country
    title
  }
}
    `;

/**
 * __useListLocationsForDropdownQuery__
 *
 * To run a query within a React component, call `useListLocationsForDropdownQuery` and pass it any options that fit your needs.
 * When your component renders, `useListLocationsForDropdownQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListLocationsForDropdownQuery({
 *   variables: {
 *   },
 * });
 */
export function useListLocationsForDropdownQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<IListLocationsForDropdownQuery, IListLocationsForDropdownQueryVariables>) {
        return ApolloReactHooks.useQuery<IListLocationsForDropdownQuery, IListLocationsForDropdownQueryVariables>(ListLocationsForDropdownDocument, baseOptions);
      }
export function useListLocationsForDropdownLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<IListLocationsForDropdownQuery, IListLocationsForDropdownQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<IListLocationsForDropdownQuery, IListLocationsForDropdownQueryVariables>(ListLocationsForDropdownDocument, baseOptions);
        }
export type ListLocationsForDropdownQueryHookResult = ReturnType<typeof useListLocationsForDropdownQuery>;
export type ListLocationsForDropdownLazyQueryHookResult = ReturnType<typeof useListLocationsForDropdownLazyQuery>;
export type ListLocationsForDropdownQueryResult = ApolloReactCommon.QueryResult<IListLocationsForDropdownQuery, IListLocationsForDropdownQueryVariables>;
export const CreateTripDocument = gql`
    mutation CreateTrip($input: CreateTripInput!) {
  createTrip(input: $input) {
    tripId
  }
}
    `;
export type ICreateTripMutationFn = ApolloReactCommon.MutationFunction<ICreateTripMutation, ICreateTripMutationVariables>;

/**
 * __useCreateTripMutation__
 *
 * To run a mutation, you first call `useCreateTripMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateTripMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createTripMutation, { data, loading, error }] = useCreateTripMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateTripMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<ICreateTripMutation, ICreateTripMutationVariables>) {
        return ApolloReactHooks.useMutation<ICreateTripMutation, ICreateTripMutationVariables>(CreateTripDocument, baseOptions);
      }
export type CreateTripMutationHookResult = ReturnType<typeof useCreateTripMutation>;
export type CreateTripMutationResult = ApolloReactCommon.MutationResult<ICreateTripMutation>;
export type CreateTripMutationOptions = ApolloReactCommon.BaseMutationOptions<ICreateTripMutation, ICreateTripMutationVariables>;
export const GetTripDocument = gql`
    query GetTrip($tripId: ID!) {
  trip(tripId: $tripId) {
    tripId
    locationId
    startDate
    endDate
    maxSeats
    detailsUrl
    active
    archived
    tags
    skus {
      sku
      type
      price
    }
    location {
      locationId
      country
      title
    }
  }
}
    `;

/**
 * __useGetTripQuery__
 *
 * To run a query within a React component, call `useGetTripQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTripQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTripQuery({
 *   variables: {
 *      tripId: // value for 'tripId'
 *   },
 * });
 */
export function useGetTripQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<IGetTripQuery, IGetTripQueryVariables>) {
        return ApolloReactHooks.useQuery<IGetTripQuery, IGetTripQueryVariables>(GetTripDocument, baseOptions);
      }
export function useGetTripLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<IGetTripQuery, IGetTripQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<IGetTripQuery, IGetTripQueryVariables>(GetTripDocument, baseOptions);
        }
export type GetTripQueryHookResult = ReturnType<typeof useGetTripQuery>;
export type GetTripLazyQueryHookResult = ReturnType<typeof useGetTripLazyQuery>;
export type GetTripQueryResult = ApolloReactCommon.QueryResult<IGetTripQuery, IGetTripQueryVariables>;
export const UpdateTripDocument = gql`
    mutation UpdateTrip($input: UpdateTripInput!) {
  updateTrip(input: $input) {
    tripId
    locationId
    startDate
    endDate
    maxSeats
    detailsUrl
    active
    tags
  }
}
    `;
export type IUpdateTripMutationFn = ApolloReactCommon.MutationFunction<IUpdateTripMutation, IUpdateTripMutationVariables>;

/**
 * __useUpdateTripMutation__
 *
 * To run a mutation, you first call `useUpdateTripMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateTripMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateTripMutation, { data, loading, error }] = useUpdateTripMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateTripMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<IUpdateTripMutation, IUpdateTripMutationVariables>) {
        return ApolloReactHooks.useMutation<IUpdateTripMutation, IUpdateTripMutationVariables>(UpdateTripDocument, baseOptions);
      }
export type UpdateTripMutationHookResult = ReturnType<typeof useUpdateTripMutation>;
export type UpdateTripMutationResult = ApolloReactCommon.MutationResult<IUpdateTripMutation>;
export type UpdateTripMutationOptions = ApolloReactCommon.BaseMutationOptions<IUpdateTripMutation, IUpdateTripMutationVariables>;
export const UpdateTripSkuDocument = gql`
    mutation UpdateTripSKU($input: UpdateTripSKUInput!) {
  updateTripSKU(input: $input) {
    sku
  }
}
    `;
export type IUpdateTripSkuMutationFn = ApolloReactCommon.MutationFunction<IUpdateTripSkuMutation, IUpdateTripSkuMutationVariables>;

/**
 * __useUpdateTripSkuMutation__
 *
 * To run a mutation, you first call `useUpdateTripSkuMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateTripSkuMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateTripSkuMutation, { data, loading, error }] = useUpdateTripSkuMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateTripSkuMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<IUpdateTripSkuMutation, IUpdateTripSkuMutationVariables>) {
        return ApolloReactHooks.useMutation<IUpdateTripSkuMutation, IUpdateTripSkuMutationVariables>(UpdateTripSkuDocument, baseOptions);
      }
export type UpdateTripSkuMutationHookResult = ReturnType<typeof useUpdateTripSkuMutation>;
export type UpdateTripSkuMutationResult = ApolloReactCommon.MutationResult<IUpdateTripSkuMutation>;
export type UpdateTripSkuMutationOptions = ApolloReactCommon.BaseMutationOptions<IUpdateTripSkuMutation, IUpdateTripSkuMutationVariables>;
export const ListTripsDocument = gql`
    query ListTrips($filter: TripsFilter) {
  trips(filter: $filter) {
    tripId
    startDate
    endDate
    locationId
    archived
    active
    maxSeats
    seatsTaken
    tags
    location {
      locationId
      country
      title
      cntry {
        name
        region
        subRegion
        intermediateRegion
      }
    }
  }
  locations {
    locationId
    country
    title
  }
}
    `;

/**
 * __useListTripsQuery__
 *
 * To run a query within a React component, call `useListTripsQuery` and pass it any options that fit your needs.
 * When your component renders, `useListTripsQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListTripsQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useListTripsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<IListTripsQuery, IListTripsQueryVariables>) {
        return ApolloReactHooks.useQuery<IListTripsQuery, IListTripsQueryVariables>(ListTripsDocument, baseOptions);
      }
export function useListTripsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<IListTripsQuery, IListTripsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<IListTripsQuery, IListTripsQueryVariables>(ListTripsDocument, baseOptions);
        }
export type ListTripsQueryHookResult = ReturnType<typeof useListTripsQuery>;
export type ListTripsLazyQueryHookResult = ReturnType<typeof useListTripsLazyQuery>;
export type ListTripsQueryResult = ApolloReactCommon.QueryResult<IListTripsQuery, IListTripsQueryVariables>;