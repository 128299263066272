import { InMemoryCache } from 'apollo-cache-inmemory';
import { ApolloClient } from 'apollo-client';
import { concat } from 'apollo-link';
import { setContext } from 'apollo-link-context';
import { HttpLink } from 'apollo-link-http';
import * as firebase from 'firebase/app';
import config from './config';

const cache = new InMemoryCache();

const authMiddleware = setContext(async () => {
  let unsubscribe: firebase.Unsubscribe = () => {
    return;
  };

  const token = await new Promise((resolve, reject) => {
    unsubscribe = firebase.auth().onAuthStateChanged(async function(user) {
      try {
        resolve(user ? await user.getIdToken() : undefined);
      } catch (err) {
        reject(err);
      }
    });
  });

  unsubscribe();

  return {
    headers: {
      authorization: token ? `Bearer ${token}` : null,
      'end-user': 'admin'
    }
  };
});

const httpLink = new HttpLink({ uri: config.apiURL });

const apolloClient = new ApolloClient({
  link: concat(authMiddleware, httpLink),
  defaultOptions: {
    query: {
      fetchPolicy: 'no-cache'
    }
  },
  cache
});

export default apolloClient;
