import { Alert, Button, Table, Tooltip } from 'antd';
import Search from 'antd/lib/input/Search';
import FlexSearch from 'flexsearch';
import { GraphQLError } from 'graphql';
import * as RA from 'ramda-adjunct';
import * as React from 'react';
import { useMemo } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { useQueryParam } from 'use-query-params';
import { StringParam } from 'use-query-params/lib/params';
import { ILocation, useListLocationsQuery } from '../../generated/graphql';
import links from '../../lib/links';
import { pickRegion } from '../../lib/utils';

const columns = [
  {
    title: 'ID',
    dataIndex: 'locationId',
    key: 'locationId'
  },
  {
    title: 'Title',
    dataIndex: 'title',
    key: 'title'
  },
  {
    title: 'Description',
    dataIndex: 'description',
    key: 'y',
    width: 300
  },
  {
    title: 'Country',
    dataIndex: 'cntry.name',
    key: 'country'
  },
  {
    title: 'Region',
    dataIndex: '',
    key: 'region',
    render: (text: string, location: ILocation) => {
      return <span>{pickRegion(location)}</span>;
    }
  },
  {
    title: 'Action',
    dataIndex: '',
    key: 'x',
    render: (text: string, location: ILocation) => (
      <section>
        <Tooltip title="Edit">
          <Button href={links.editLocation(location.locationId)} icon="form" type="link" />
        </Tooltip>
        &nbsp;
        <Tooltip title="Go to checkout">
          <Button href={links.checkout(location.locationId)} icon="global" type="link" />
        </Tooltip>
        &nbsp;
        <Tooltip title="View trips">
          <Button
            href={links.trips({ locationId: location.locationId, archived: false })}
            icon="ordered-list"
            type="link"
          />
        </Tooltip>
      </section>
    )
  }
];

interface Props extends RouteComponentProps {}

function List(props: Props) {
  const [search = '', setSearch] = useQueryParam('search', StringParam);
  const query = useListLocationsQuery();

  const locations = query.data?.locations ?? [];

  const index = useMemo(() => {
    return FlexSearch.create({
      doc: {
        id: 'locationId',
        field: [
          'locationId',
          'title',
          'description',
          'cntry:name',
          'cntry:region',
          'cntry:subRegion',
          'cntry:intermediateRegion'
        ]
      }
    } as any).add(locations);
  }, [locations]);

  const errors = query.error?.graphQLErrors.map((error: GraphQLError, i: number) => (
    <Alert key={i} message={error.message} type="error" />
  ));

  return (
    <>
      <Button
        type="primary"
        htmlType="button"
        href={links.createLocation()}
        style={{
          marginBottom: '1rem'
        }}
      >
        Create Location
      </Button>
      <Search
        name="search"
        size="large"
        onSearch={value => setSearch(value)}
        style={{
          marginBottom: '1rem'
        }}
      />
      <Table
        loading={query.loading}
        columns={columns}
        dataSource={RA.isNotEmpty(search) ? index.search(search) : locations}
        rowKey="locationId"
        bordered
      />
      <br />
      {!!query.error && <Alert message={query.error.message} type="error" />}
      {errors}
    </>
  );
}

export default List;
