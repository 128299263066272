import { Button, Card, Checkbox, Descriptions, Input, Select } from 'antd';
import { Field, FieldArray, FieldProps, Form } from 'formik';
import * as R from 'ramda';
import * as RA from 'ramda-adjunct';
import * as React from 'react';
import styled from 'styled-components';
import SelectTrip from '../../routes/Booking/components/SelectTrip';
import TravelerForm from '../../routes/Booking/components/TravelerForm';

function BookingForm() {
  return (
    <Form>
      <Layout>
        <Card
          style={{
            gridArea: 'trip'
          }}
          size="small"
          title="Trip"
        >
          <Descriptions column={1}>
            <Descriptions.Item label="Trip">
              <Field
                name="tripId"
                render={({ field, form }: FieldProps<any>) => (
                  <SelectTrip
                    style={{
                      minWidth: '500px'
                    }}
                    onChange={([_, tripId]) => {
                      form.setFieldValue(field.name, tripId);
                    }}
                  />
                )}
              />
            </Descriptions.Item>
          </Descriptions>
        </Card>

        <Card size="small" title="Booking Details">
          <Descriptions column={1}>
            <Descriptions.Item label="Status">
              <Field
                name="status"
                render={({ field, form }: FieldProps<any>) => (
                  <Select
                    style={{
                      minWidth: '300px'
                    }}
                    onChange={(value: string) => form.setFieldValue(field.name, value)}
                    value={field.value}
                    optionFilterProp="children"
                    filterOption={(input: string, option: any) =>
                      option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                    }
                    showSearch
                  >
                    {R.map((status: string) => (
                      <Select.Option key={status} value={status}>
                        {status}
                      </Select.Option>
                    ))(['APPROVED', 'PENDING', 'CANCELLED_REFUNDED', 'CANCELLED_HELD'])}
                  </Select>
                )}
              />
            </Descriptions.Item>
            <Descriptions.Item label="Return Customer">
              <Field
                name="returnCustomer"
                render={({ field, form }: FieldProps<any>) => (
                  <Checkbox
                    onChange={() => form.setFieldValue(field.name, !field.value)}
                    defaultChecked={field.value}
                  />
                )}
              />
            </Descriptions.Item>
            <Descriptions.Item label="Room Arrangement">
              <Field
                name="roomArrangement"
                render={({ field, form }: FieldProps<any>) => (
                  <Select
                    style={{
                      minWidth: '300px'
                    }}
                    onChange={(value: string) => form.setFieldValue(field.name, value)}
                    value={field.value}
                    optionFilterProp="children"
                    filterOption={(input: string, option: any) =>
                      option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                    }
                    showSearch
                  >
                    {R.map((status: string) => (
                      <Select.Option key={status} value={status}>
                        {status}
                      </Select.Option>
                    ))(['solo', 'friend', 'couple'])}
                  </Select>
                )}
              />
            </Descriptions.Item>
            <Descriptions.Item label="Friend Name">
              <Field name="friendName" render={({ field }: FieldProps<any>) => <Input {...field} />} />
            </Descriptions.Item>
          </Descriptions>

          <FieldArray
            name="travelers"
            render={arrayHelpers => (
              <div
                style={{
                  display: 'grid',
                  gridGap: '1rem',
                  gridTemplateColumns: 'repeat(2, 1fr)',
                  gridTemplateRows: 'auto'
                }}
              >
                <Field
                  name="travelers"
                  render={({ field }: FieldProps<any>) => (
                    <>
                      {RA.mapIndexed((_: any, index: number) => (
                        <Card
                          type="inner"
                          title={
                            <section style={{ display: 'flex' }}>
                              <span style={{ flex: 1 }}>Traveler {index + 1}</span>
                              <Button
                                type="dashed"
                                htmlType="button"
                                icon="minus"
                                onClick={() => arrayHelpers.remove(index)}
                              />
                            </section>
                          }
                          key={index}
                        >
                          <TravelerForm index={index} />
                        </Card>
                      ))(field.value)}
                      {field.value.length <= 1 && (
                        <Button
                          type="dashed"
                          htmlType="button"
                          icon="plus"
                          onClick={() => arrayHelpers.push(RA.stubObject())}
                        />
                      )}
                    </>
                  )}
                />
              </div>
            )}
          />
        </Card>

        <div>
          <Field
            name="returnCustomer"
            render={({ form }: FieldProps<any>) => (
              <Button type="primary" htmlType="submit" icon="save" loading={form.isSubmitting}>
                Save
              </Button>
            )}
          />
        </div>
      </Layout>
    </Form>
  );
}

const Layout = styled('section')`
  width: 100%;
  max-width: 1024px;
  margin: 0 auto;
  display: grid;
  grid-gap: 1rem;
  grid-template-columns: repeat(1, 1fr);
  grid-template-rows: auto;
  grid-template-areas:
    'trip'
    'order'
    'booking';
`;

export default BookingForm;
