import { DatePicker, Descriptions, Input, Select } from 'antd';
import { Field, FieldProps } from 'formik';
import moment from 'moment';
import * as R from 'ramda';
import * as React from 'react';
import countries from '../../../lib/countries';

interface Props {
  index: number;
}

function TravelerForm(props: Props) {
  const { index } = props;
  return (
    <>
      <Descriptions title="Info" column={1}>
        <Descriptions.Item label="First Name">
          <Field
            name={`travelers[${index}].firstName`}
            render={({ field }: FieldProps<any>) => <Input {...field} type="text" required />}
          />
        </Descriptions.Item>
        <Descriptions.Item label="Last Name">
          <Field
            name={`travelers[${index}].lastName`}
            render={({ field }: FieldProps<any>) => <Input {...field} type="text" required />}
          />
        </Descriptions.Item>
        <Descriptions.Item label="Gender">
          <Field
            name={`travelers[${index}].gender`}
            render={({ field, form }: FieldProps<any>) => (
              <Select
                style={{
                  minWidth: '200px'
                }}
                onChange={(value: string) => form.setFieldValue(field.name, value)}
                value={field.value}
                optionFilterProp="children"
                filterOption={(input: string, option: any) =>
                  option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
                showSearch
              >
                <Select.Option value="male">male</Select.Option>
                <Select.Option value="female">female</Select.Option>
              </Select>
            )}
          />
        </Descriptions.Item>
        <Descriptions.Item label="Birthday">
          <Field
            name={`travelers[${index}].birthday`}
            render={({ field, form }: FieldProps<any>) => (
              <DatePicker
                format="MMM Do YYYY"
                onChange={(date: moment.Moment | null) =>
                  form.setFieldValue(field.name, date ? date.toISOString() : undefined)
                }
                value={field.value ? moment(field.value) : undefined}
              />
            )}
          />
        </Descriptions.Item>
        <Descriptions.Item label="Email">
          <Field
            name={`travelers[${index}].email`}
            render={({ field }: FieldProps<any>) => <Input {...field} type="email" required />}
          />
        </Descriptions.Item>
        <Descriptions.Item label="Phone">
          <Field
            name={`travelers[${index}].phone`}
            render={({ field }: FieldProps<any>) => <Input {...field} type="tel" required />}
          />
        </Descriptions.Item>
      </Descriptions>

      <Descriptions title="Address" column={1}>
        <Descriptions.Item label="Street">
          <Field
            name={`travelers[${index}].address.line1`}
            render={({ field }: FieldProps<any>) => <Input {...field} type="text" required />}
          />
        </Descriptions.Item>
        <Descriptions.Item label="Street 2">
          <Field
            name={`travelers[${index}].address.line2`}
            render={({ field }: FieldProps<any>) => <Input {...field} type="text" />}
          />
        </Descriptions.Item>
        <Descriptions.Item label="City">
          <Field
            name={`travelers[${index}].address.city`}
            render={({ field }: FieldProps<any>) => <Input {...field} type="text" required />}
          />
        </Descriptions.Item>
        <Descriptions.Item label="State">
          <Field
            name={`travelers[${index}].address.region`}
            render={({ field }: FieldProps<any>) => <Input {...field} type="text" required />}
          />
        </Descriptions.Item>
        <Descriptions.Item label="Postal Code">
          <Field
            name={`travelers[${index}].address.postalCode`}
            render={({ field }: FieldProps<any>) => <Input {...field} type="text" required />}
          />
        </Descriptions.Item>
        <Descriptions.Item label="Country">
          <Field
            name={`travelers[${index}].address.country`}
            render={({ field, form }: FieldProps<any>) => (
              <Select
                style={{
                  minWidth: '300px'
                }}
                onChange={(value: string) => form.setFieldValue(field.name, value)}
                value={field.value}
                optionFilterProp="children"
                filterOption={(input: string, option: any) =>
                  option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
                showSearch
              >
                {R.map((country: { name: string; code: string }) => (
                  <Select.Option key={country.code} value={country.code}>
                    {country.name}
                  </Select.Option>
                ))(countries)}
              </Select>
            )}
          />
        </Descriptions.Item>
      </Descriptions>

      <Descriptions title="Passport" column={1}>
        <Descriptions.Item label="Number">
          <Field
            name={`travelers[${index}].passport.number`}
            render={({ field }: FieldProps<any>) => <Input {...field} type="text" />}
          />
        </Descriptions.Item>
        <Descriptions.Item label="Expiration">
          <Field
            name={`travelers[${index}].passport.expiration`}
            render={({ field, form }: FieldProps<any>) => (
              <DatePicker.MonthPicker
                onChange={(date: moment.Moment | null) =>
                  form.setFieldValue(field.name, date ? date.format('YYYY-MM') : undefined)
                }
                value={field.value ? moment(field.value) : undefined}
              />
            )}
          />
        </Descriptions.Item>
        <Descriptions.Item label="Country">
          <Field
            name={`travelers[${index}].passport.country`}
            render={({ field, form }: FieldProps<any>) => (
              <Select
                style={{
                  minWidth: '300px'
                }}
                onChange={(value: string) => form.setFieldValue(field.name, value)}
                value={field.value}
                optionFilterProp="children"
                filterOption={(input: string, option: any) =>
                  option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
                showSearch
              >
                {R.map((country: { name: string; code: string }) => (
                  <Select.Option key={country.code} value={country.code}>
                    {country.name}
                  </Select.Option>
                ))(countries)}
              </Select>
            )}
          />
        </Descriptions.Item>
      </Descriptions>
    </>
  );
}

export default TravelerForm;
