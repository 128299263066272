import { Alert } from 'antd';
import * as firebase from 'firebase/app';
import { Formik } from 'formik';
import * as React from 'react';
import styled from 'styled-components';
import LoginForm from '../../components/LoginForm/LoginForm';

function LoginPage() {
  return (
    <Formik
      initialValues={{}}
      onSubmit={async (input: any, formikActions: any) => {
        try {
          formikActions.setSubmitting(true);
          formikActions.setStatus(undefined);
          await firebase.auth().signInWithEmailAndPassword(input.email, input.password);
        } catch (err) {
          formikActions.setStatus(err);
        } finally {
          formikActions.setSubmitting(false);
        }
      }}
      render={formikProps => (
        <Layout>
          <LoginForm formik={formikProps} />
          {!!formikProps.status && <Alert message={formikProps.status.message} type="error" showIcon closable />}
        </Layout>
      )}
    />
  );
}

const Layout = styled('section')`
  width: 100%;
  max-width: 1024px;
  margin: 0 auto;
  padding-top: 5rem;
`;

export default LoginPage;
