import { Alert, Button, Table, Tooltip } from 'antd';
import Search from 'antd/lib/input/Search';
import { GraphQLError } from 'graphql';
import { DateTime } from 'luxon';
import * as L from 'partial.lenses';
import * as R from 'ramda';
import * as RA from 'ramda-adjunct';
import * as React from 'react';
import { useCallback } from 'react';
import { useQueryParam } from 'use-query-params';
import { StringParam } from 'use-query-params/lib/params';
import { IBooking, ITraveler, useListBookingsQuery } from '../../generated/graphql';
import countries from '../../lib/countries';
import links from '../../lib/links';
import EditableCell from './components/EditableCell';

const columns = [
  {
    title: 'Booking',
    dataIndex: 'bookingId',
    key: 'bookingId'
  },
  {
    title: 'Travelers',
    dataIndex: 'travelerNames',
    key: 'travelers',
    render: (text: string, { travelers }: IBooking) =>
      R.pipe(
        R.map((traveler: ITraveler) => `${traveler.firstName} ${traveler.lastName}`),
        R.join(', ')
      )(travelers!)
  },
  {
    title: 'Country',
    dataIndex: 'trip.location.country',
    key: 'country',
    render: (text: string) => {
      const country = R.find(R.propEq('code', text))(countries);
      return R.path(['name'])(country);
    }
  },
  {
    title: 'Location',
    dataIndex: 'trip.location.title',
    key: 'title'
  },
  {
    title: 'Status',
    dataIndex: 'status',
    key: 'status',
    editable: true
  },
  {
    title: 'Start Date',
    dataIndex: 'trip.startDate',
    key: 'startDate',
    render: (startDate: string) => <span>{DateTime.fromISO(startDate).toLocaleString(DateTime.DATE_MED)}</span>
  },
  {
    title: 'Create Time',
    dataIndex: 'createTime',
    key: 'createTime',
    render: (createTime: string) => <span>{DateTime.fromISO(createTime).toLocaleString(DateTime.DATETIME_FULL)}</span>
  },
  {
    title: 'Action',
    dataIndex: '',
    key: 'x',
    render: (text: string, booking: IBooking) => (
      <section>
        <Tooltip title="Edit">
          <Button icon="form" type="link" href={links.editBooking(booking.bookingId)} />
        </Tooltip>
      </section>
    )
  }
];

function List() {
  const [search = '', setSearch] = useQueryParam('search', StringParam);
  const pageSize = 25;
  const query = useListBookingsQuery({
    variables: {
      query: search,
      pageSize
    },
    notifyOnNetworkStatusChange: true
  });

  const pageToken = query.data?.bookingPage?.nextPageToken;
  const bookings = query.data?.bookingPage?.bookings;

  const loadMore = useCallback(async () => {
    if (RA.isNotNilOrEmpty(pageToken)) {
      await query.fetchMore({
        variables: {
          query: search,
          pageSize: 10,
          pageToken
        },
        updateQuery: (previousQueryResult: any, { fetchMoreResult }: any) => {
          const previousBookings = previousQueryResult.bookingPage.bookings;
          const newBookings = fetchMoreResult.bookingPage.bookings;
          return {
            bookingPage: {
              nextPageToken: fetchMoreResult.bookingPage.nextPageToken ?? '',
              bookings: [...previousBookings, ...(newBookings ?? [])],
              __typename: previousQueryResult.bookingPage.__typename
            }
          };
        }
      });
    }
  }, [pageToken, search]);

  const errors = query.error?.graphQLErrors.map((error: GraphQLError, i: number) => (
    <Alert key={i} message={error.message} type="error" />
  ));

  const cols = L.modify([L.find(R.whereEq({ editable: true }))], (c: any) => ({
    ...c,
    onCell: (booking: IBooking) => ({
      booking,
      editable: true
    })
  }))(columns);

  return (
    <>
      <Button
        type="primary"
        htmlType="button"
        href={links.createBooking()}
        style={{
          marginBottom: 16
        }}
      >
        Create Booking
      </Button>

      <Search
        disabled={query.loading}
        name="search"
        size="large"
        onSearch={value => {
          setSearch(value);
          query.refetch();
        }}
        style={{
          marginBottom: '1rem'
        }}
      />

      <Table
        components={{
          body: {
            cell: EditableCell
          }
        }}
        loading={query.loading}
        columns={cols}
        dataSource={bookings!}
        rowKey="bookingId"
        pagination={false}
        footer={() => (
          <Button type="default" onClick={loadMore} loading={query.loading} disabled={RA.isNilOrEmpty(pageToken)}>
            Loading More
          </Button>
        )}
        bordered
      />

      <br />

      {!!query.error && <Alert message={query.error.message} type="error" />}

      {errors}
    </>
  );
}

export default List;
