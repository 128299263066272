import { Button, Checkbox, DatePicker, Form, Input, InputNumber, Select } from 'antd';
import { RangePickerValue } from 'antd/lib/date-picker/interface';
import { connect, Field, FieldProps, Form as FormikForm } from 'formik';
import moment from 'moment';
import * as R from 'ramda';
import * as RA from 'ramda-adjunct';
import * as React from 'react';
import * as formatter from '../../../lib/formatter';
import * as parser from '../../../lib/parser';

const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 4 }
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 20 }
  }
};

const tailFormItemLayout = {
  wrapperCol: {
    xs: {
      span: 24,
      offset: 0
    },
    sm: {
      span: 20,
      offset: 4
    }
  }
};

interface ILocation {
  locationId: string;
  country: string;
  title: string;
}

interface Props {
  locations?: Array<ILocation>;
  disableLocation?: boolean;
  disablePrice?: boolean;
  formik: any;
}

function TripForm(props: Props) {
  const { locations = [], disableLocation, disablePrice, formik } = props;
  return (
    <FormikForm>
      <Form.Item {...formItemLayout} label="Location">
        <Field
          name="locationId"
          render={({ field, form }: FieldProps<any>) => (
            <Select
              onChange={(value: string) => form.setFieldValue(field.name, value)}
              value={field.value}
              size="large"
              disabled={disableLocation}
            >
              {R.map((location: ILocation) => (
                <Select.Option key={location.locationId} value={location.locationId}>
                  {location.title}
                </Select.Option>
              ))(locations)}
            </Select>
          )}
        />
      </Form.Item>

      <Form.Item {...formItemLayout} label="Dates">
        <DatePicker.RangePicker
          format="MMM Do YYYY"
          placeholder={['Start Date', 'End Date']}
          onChange={(dates: RangePickerValue) => {
            formik.setFieldValue('startDate', dates[0] ? dates[0].toISOString() : undefined);
            formik.setFieldValue('endDate', dates[1] ? dates[1].toISOString() : undefined);
          }}
          value={
            [
              formik.values.startDate ? moment(formik.values.startDate) : undefined,
              formik.values.endDate ? moment(formik.values.endDate) : undefined
            ] as any
          }
          size="large"
          disabledDate={(startTime: moment.Moment | null) => {
            if (!startTime) {
              return false;
            }
            return startTime.valueOf() < Date.now();
          }}
        />
      </Form.Item>

      <Field
        name="maxSeats"
        render={({ field, form }: FieldProps<any>) => (
          <Form.Item {...formItemLayout} label="Max Seats">
            <InputNumber
              onChange={(value: number | undefined) => form.setFieldValue(field.name, value)}
              value={field.value}
              min={0}
              size="large"
            />
          </Form.Item>
        )}
      />

      <Field
        name="detailsUrl"
        render={({ field }: FieldProps<any>) => (
          <Form.Item {...formItemLayout} label="Details URL">
            <Input {...field} type="url" required size="large" />
          </Form.Item>
        )}
      />

      <Field
        name="tags"
        render={({ field, form }: FieldProps<any>) => (
          <Form.Item {...formItemLayout} label="Tags">
            <Select
              mode="tags"
              value={field.value}
              onChange={(values: any) => form.setFieldValue(field.name, values)}
              size="large"
            >
              {R.pipe<Array<string>, Array<string>, Array<React.ReactElement<any>>>(
                R.defaultTo(RA.stubArray()),
                R.map((text: string) => (
                  <Select.Option key={text} value={text}>
                    {text}
                  </Select.Option>
                ))
              )(field.value)}
            </Select>
          </Form.Item>
        )}
      />

      <Field
        name="active"
        render={({ field, form }: FieldProps<any>) => (
          <Form.Item {...formItemLayout} label="Disabled">
            <Checkbox
              onChange={() => form.setFieldValue(field.name, !field.value)}
              defaultChecked={!field.value}
              checked={!field.value}
            />
          </Form.Item>
        )}
      />

      <Field
        name="archived"
        render={({ field, form }: FieldProps<any>) => (
          <Form.Item {...formItemLayout} label="Archived">
            <Checkbox
              onChange={() => form.setFieldValue(field.name, !field.value)}
              defaultChecked={field.value}
              checked={field.value}
            />
          </Form.Item>
        )}
      />

      <Field
        name="earlyPrice"
        render={({ field, form }: FieldProps<any>) => (
          <Form.Item {...formItemLayout} label="Early Price">
            <Input
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                const { value } = event.target;
                form.setFieldValue(field.name, parser.parseCurrency(value));
              }}
              value={formatter.formatDollars(field.value)}
              required
              disabled={disablePrice}
              size="large"
            />
          </Form.Item>
        )}
      />

      <Field
        name="depositPrice"
        render={({ field, form }: FieldProps<any>) => (
          <Form.Item {...formItemLayout} label="Deposit Price">
            <Input
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                const { value } = event.target;
                form.setFieldValue(field.name, parser.parseCurrency(value));
              }}
              value={formatter.formatDollars(field.value)}
              required
              disabled={disablePrice}
              size="large"
            />
          </Form.Item>
        )}
      />

      <Field
        name="fullPrice"
        render={({ field, form }: FieldProps<any>) => (
          <Form.Item {...formItemLayout} label="Full Price">
            <Input
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                const { value } = event.target;
                form.setFieldValue(field.name, parser.parseCurrency(value));
              }}
              value={formatter.formatDollars(field.value)}
              required
              disabled={disablePrice}
              size="large"
            />
          </Form.Item>
        )}
      />

      <Form.Item {...tailFormItemLayout}>
        <Button type="primary" htmlType="submit" loading={formik.isSubmitting}>
          Save
        </Button>
      </Form.Item>
    </FormikForm>
  );
}

export default connect<any, any>(TripForm);
