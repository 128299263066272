import { Layout } from 'antd';
import * as React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import './App.css';
import Navbar from './components/Navbar/Navbar';
import PrivateRoute from './components/PrivateRoute/PrivateRoute';
import PublicRoute from './components/PublicRoute/PublicRoute';
import useAuth from './lib/useAuth';
import * as Booking from './routes/Booking';
import * as Coupon from './routes/Coupon';
import * as Location from './routes/Location';
import LoginPage from './routes/LoginPage/LoginPage';
import NotFound from './routes/NotFound/NotFound';
import Settings from './routes/Settings/Settings';
import * as Trip from './routes/Trip';

function App() {
  const { isAuthenticated } = useAuth();
  return (
    <main>
      <Switch>
        <Route exact path="/" render={() => <Redirect to="/login" />} />

        <PublicRoute
          exact
          isAuthenticated={isAuthenticated}
          redirectTo="/bookings"
          path="/login"
          component={LoginPage}
        />

        <PrivateRoute
          isAuthenticated={isAuthenticated}
          redirectTo="/login"
          path={['/locations', '/trips', '/coupons', '/bookings', '/settings', '/abandoned-users']}
          render={() => (
            <Layout style={{ background: '#FFF' }}>
              <Navbar />
              <Layout.Content style={{ padding: '1rem' }}>
                <Switch>
                  <Route
                    path={['/locations']}
                    render={() => (
                      <Switch>
                        <Route exact path="/locations" component={Location.List} />
                        <Route exact path="/locations/create" component={Location.Create} />
                        <Route exact path="/locations/:locationId/edit" component={Location.Edit} />
                      </Switch>
                    )}
                  />

                  <Route
                    path={['/trips']}
                    render={() => (
                      <Switch>
                        <Route exact path="/trips" component={Trip.List} />
                        <Route exact path="/trips/create" component={Trip.Create} />
                        <Route exact path="/trips/:tripId/edit" component={Trip.Edit} />
                      </Switch>
                    )}
                  />

                  <Route
                    path="/coupons"
                    render={() => (
                      <Switch>
                        <Route exact path="/coupons" component={Coupon.List} />
                        <Route exact path="/coupons/create" component={Coupon.Create} />
                        <Route exact path="/coupons/:couponId/edit" component={Coupon.Edit} />
                      </Switch>
                    )}
                  />

                  <Route
                    path="/bookings"
                    render={() => (
                      <Switch>
                        <Route exact path="/bookings" component={Booking.List} />
                        <Route exact path="/bookings/create" component={Booking.Create} />
                        <Route exact path="/bookings/:bookingId/edit" component={Booking.Edit} />
                      </Switch>
                    )}
                  />

                  <Route exact path="/settings" component={Settings} />
                </Switch>
              </Layout.Content>
            </Layout>
          )}
        />

        <Route component={NotFound} />
      </Switch>
    </main>
  );
}

export default App;
