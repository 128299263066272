import { Alert } from 'antd';
import { message } from 'antd/es';
import { Formik } from 'formik';
import * as React from 'react';
import { RouteComponentProps } from 'react-router-dom';
import AsyncComponent from '../../components/AsyncComponent/AsyncComponent';
import Layout from '../../components/Layout';
import { useCreateTripMutation, useListLocationsForDropdownQuery } from '../../generated/graphql';
import links from '../../lib/links';
import TripForm from './components/TripForm';

interface Props extends RouteComponentProps<{ locationId: string }> {}

function Create(props: Props) {
  const query = useListLocationsForDropdownQuery();
  const [createTrip] = useCreateTripMutation();

  return (
    <AsyncComponent
      {...query}
      render={({ locations }) => (
        <Formik
          initialValues={{
            active: true
          }}
          onSubmit={async (input: any, formikActions: any) => {
            try {
              formikActions.setSubmitting(true);
              formikActions.setStatus(undefined);
              const { data } = await createTrip({ variables: { input } });
              message.success('Trip create successfully!');
              if (data) {
                props.history.push(links.editTrip(data.createTrip!.tripId));
              }
            } catch (err) {
              formikActions.setStatus(err);
              message.error('Trip create failed!');
            } finally {
              formikActions.setSubmitting(false);
            }
          }}
          render={({ status }: any) => (
            <Layout>
              <TripForm locations={locations} />
              {!!status && <Alert message={status.message} type="error" showIcon closable />}
            </Layout>
          )}
        />
      )}
    />
  );
}

export default Create;
