import * as React from 'react';
import LoadingSpinner from '../LoadingSpinner/LoadingSpinner';

interface Props {
  loading?: boolean;
  error?: any;
  data?: any;
  allErrors?: boolean;

  render(data: any): React.ReactElement<any> | null;
}

function AsyncComponent(props: Props) {
  const { loading, error, data, allErrors, render } = props;

  if (loading) {
    return <LoadingSpinner />;
  }

  if (!allErrors && error) {
    return <div>{error.message}</div>;
  }

  if (!data) {
    return <div>data not found!</div>;
  }

  return render(data);
}

export default AsyncComponent;
