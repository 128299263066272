import { Button, Empty, Layout } from 'antd';
import * as React from 'react';
import links from '../../lib/links';

function NotFound() {
  return (
    <Layout>
      <Layout.Content
        style={{
          background: '#fff',
          paddingTop: '5rem'
        }}
      >
        <Empty description={<span>Page not found.</span>}>
          <Button type="primary" href={links.login()}>
            Login
          </Button>
        </Empty>
      </Layout.Content>
    </Layout>
  );
}

export default NotFound;
