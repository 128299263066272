import { Form, Icon, Input, Modal, Select, Upload } from 'antd';
import { RcFile } from 'antd/es/upload';
import firebase from 'firebase/app';
import * as R from 'ramda';
import * as RA from 'ramda-adjunct';
import * as React from 'react';
import { useState } from 'react';
import countries from '../../lib/countries';
import { useForm } from '../../routes/Coupon/components/Form';

const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 4 }
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 20 }
  }
};

function LocationForm() {
  const formik = useForm();
  const [previewVisible, setPreviewVisible] = useState(false);

  return (
    <>
      <Form.Item {...formItemLayout} label="Country">
        <Select
          onChange={(value: string) => formik.setFieldValue('country', value)}
          value={formik.values.country}
          size="large"
        >
          {R.map((country: { name: string; code: string }) => (
            <Select.Option key={country.code} value={country.code}>
              {country.name}
            </Select.Option>
          ))(countries)}
        </Select>
      </Form.Item>

      <Form.Item {...formItemLayout} label="Title">
        <Input
          name="title"
          onChange={formik.handleChange}
          value={formik.values.title}
          type="text"
          size="large"
          required
        />
      </Form.Item>

      <Form.Item {...formItemLayout} label="Description">
        <Input
          name="description"
          onChange={formik.handleChange}
          value={formik.values.description}
          type="text"
          size="large"
          required
        />
      </Form.Item>

      <Form.Item {...formItemLayout} label="Included">
        <Select
          mode="tags"
          value={formik.values.included}
          onChange={(values: any) => formik.setFieldValue('included', values)}
          size="large"
        >
          {R.pipe<Array<string>, Array<string>, Array<React.ReactElement<any>>>(
            R.defaultTo(RA.stubArray()),
            R.map((text: string) => (
              <Select.Option key={text} value={text}>
                {text}
              </Select.Option>
            ))
          )(formik.values.included)}
        </Select>
      </Form.Item>

      <Form.Item {...formItemLayout} label="Not Included">
        <Select
          mode="tags"
          value={formik.values.notIncluded}
          onChange={(values: any) => formik.setFieldValue('notIncluded', values)}
          size="large"
        >
          {R.pipe<Array<string>, Array<string>, Array<React.ReactElement<any>>>(
            R.defaultTo(RA.stubArray()),
            R.map((text: string) => (
              <Select.Option key={text} value={text}>
                {text}
              </Select.Option>
            ))
          )(formik.values.notIncluded)}
        </Select>
      </Form.Item>

      <Form.Item {...formItemLayout} label="Image">
        <Upload
          accept="image/*"
          action={async (file: RcFile) => {
            const storageRef = firebase.storage().ref();
            const snapshot = await storageRef
              .child(`images/${Date.now()}-${file.name}`)
              .put(file)
              .then();
            const downloadURL = await snapshot.ref.getDownloadURL();
            formik.setFieldValue('image', downloadURL);
            return '';
          }}
          listType="picture-card"
          fileList={[
            {
              uid: '-1',
              name: '',
              size: 0,
              type: 'image/*',
              url: formik.values.image
            }
          ]}
          onPreview={() => setPreviewVisible(true)}
        >
          <div>
            <Icon type="plus" />
            <div className="ant-upload-text">Upload</div>
          </div>
        </Upload>
        <Modal visible={previewVisible} footer={null} onCancel={() => setPreviewVisible(false)}>
          <img alt="" style={{ width: '100%' }} src={formik.values.image} />
        </Modal>
      </Form.Item>
    </>
  );
}

export default LocationForm;
