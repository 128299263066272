import { Alert as AntdAlert } from 'antd';
import { ApolloError } from 'apollo-client';
import * as RA from 'ramda-adjunct';
import * as React from 'react';

interface Props {
  error?: string | Error | ApolloError;
  children?: any;
}

function Alert(props: Props) {
  const { error, children } = props;

  switch (true) {
    case error instanceof ApolloError:
      return (error as ApolloError).graphQLErrors.map((err, index) => (
        <AntdAlert key={index} message={err.message} type="error" showIcon closable />
      ));
    case error instanceof Error:
      return <AntdAlert message={(error as Error).message} type="error" showIcon closable />;
    case RA.isNotNil(children):
      return children;
    default:
      return null;
  }
}

export default Alert;
