import * as changeCase from 'change-case';
import * as L from 'partial.lenses';
import * as R from 'ramda';

const buildQueryParams = R.pipe<any, any, any, any, any, string>(
  R.toPairs,
  L.modify([L.elems, 0], changeCase.snakeCase),
  L.modify([L.elems, 1], (value: any) => (typeof value === 'boolean' ? Number(value) : value)),
  L.modify(L.elems, R.join('=')),
  R.join('&')
);

export default buildQueryParams;
